import React, { useEffect, useState, useContext } from "react";
import { instance } from "../../lib/axios";
import HeaderTable from "../../components/table/headerTable.js";
import { OffersBodyTAble } from "../../components/table/bodyTable.js";
import NextPageList from "../../components/table/nextPage.js";
import { AuthContext } from "../../context/Auth";

const offersTitels = [
	{ with: "25%", title: "Travail" },
	{ with: "15%", title: "Catégorie" },
	{ with: "20%", title: "Type" },
	{ with: "12%", title: "Status" },
	{ title: "Date" },
	{with: "20%", title: "condidate"},
];

const ManageAnnoncesList = ({ data }) => {
	const [offers, setOffers] = useState([]);
	const [page, setPage] = useState(0);
	const [rowslenght, setRowslenght] = useState(5);
	const { auth } = useContext(AuthContext);

	const queryParams = {
		sort: "desc",
		skip: page,
		take: rowslenght,
	};
	useEffect(() => {
		instance
			.get(`/annonces/${auth?.id}`, { params: queryParams })
			.then((res) => {
				setOffers(res?.data?.results);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<div
			style={{
				justifySelf: "center",
				padding: "100px 0px",
				width: "80%",
			}}
		>
			<div className="pxp-dashboard-content-details">
				<h1>Annonces d'emplois</h1>
				<div className="mt-4 mt-lg-5">
					<div className="row justify-content-between align-content-center">
						<div className="col-auto order-1 order-sm-2">
							<div className="pxp-candidate-dashboard-jobs-search mb-3"></div>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-hover align-middle">
							<HeaderTable titels={offersTitels} />
							<OffersBodyTAble data={offers} />
						</table>
						<NextPageList
							setPage={setPage}
							page={page}
							rowslenght={rowslenght}
							setRowslenght={setRowslenght}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ManageAnnoncesList;
