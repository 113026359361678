import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import onContentScroll from "../../lib/main/onContentScroll";
import handlePreloader from "../../lib/main/handlePreloader";
import windowResizeHandler from "../../lib/main/windowResizeHandler";
import $ from "jquery";
import PreLoader from "../PreLoader";

const Header = ({ header, nav, logo, extraNav }) => {
	useEffect(() => {
		window.onscroll = function () {
			onContentScroll();
		};

		handlePreloader();

		windowResizeHandler();

		$(window).resize(function () {
			windowResizeHandler();
		});

		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<PreLoader />
			<header className="pxp-header fixed-top pxp-no-bg">
				<div className="pxp-container">
					{/* <div className="pxp-header-container"> */}
					<div style={{display: "flex", flexDirection: "row", alignItems: "center", height:"80px", justifyContent: "space-between"}}>
						<div className="pxp-logo">
							<Link to="/" className="pxp-animate">
								<img
									style={{ height: "37px" }}
									src={logo}
									alt="logo"
								/>
							</Link>
						</div>
						<div className="pxp-nav-trigger navbar d-xl-none flex-fill">
							<Link
								to={"#"}
								role="button"
								data-bs-toggle="offcanvas"
								data-bs-target="#pxpMobileNav"
								aria-controls="pxpMobileNav"
							>
								<div className="pxp-line-1"></div>
								<div className="pxp-line-2"></div>
								<div className="pxp-line-3"></div>
							</Link>
							<div
								className="offcanvas offcanvas-start pxp-nav-mobile-container"
								tabIndex={"-1"}
								id="pxpMobileNav"
							>
								<div className="offcanvas-header">
									<div className="pxp-logo">
										<Link to="/" className="pxp-animate">
											<img
												style={{ height: "37px" }}
												src={logo}
												alt="logo"
											/>
										</Link>
									</div>
									<button
										type="button"
										className="btn-close text-reset"
										data-bs-dismiss="offcanvas"
										aria-label="Close"
									></button>
								</div>
								<div className="offcanvas-body">
									<nav className="pxp-nav-mobile">
										<ul className="navbar-nav justify-content-end flex-grow-1" >
											<li className="nav-item">
												<Link 
													to="/"
													role="button"
													className="nav-link"
												>
													Accueil
												</Link>
											</li>
											<li className="nav-item">
												<Link
													to="/candidat"
													role="button"
													className="nav-link"
												>
													Services Candidats
												</Link>
											</li>
											<li className="nav-item">
												<Link
													to="/employer"
													role="button"
													className="nav-link"
												>
													Services Employeurs
												</Link>
											</li>
											<li className="nav-item">
												<Link
													to="/about"
													role="button"
													className="nav-link"
												>
													À propos de World select
												</Link>
											</li>
											<li className="nav-item">
												<Link
													to="/contact"
													role="button"
													className="nav-link"
												>
													Contact
												</Link>
											</li>
										</ul>
									</nav>
								</div>
							</div>
						</div>
						{/* @navlink */}
						<nav className={nav}>
							<ul>
								<li>
									<NavLink to="/" className="dropdown-toggle">
										Accueil
									</NavLink>
								</li>
								<li>
									<NavLink
										to="/candidat"
										className="dropdown-toggle"
									>
										Services Candidats
									</NavLink>
								</li>
								<li>
									<NavLink
										to="/employer"
										className="dropdown-toggle"
									>
										Services Employeurs
									</NavLink>
								</li>
								<li>
									<NavLink
										to="/about"
										className="dropdown-toggle"
									>
										À propos de World select
									</NavLink>
								</li>
								<li>
									<NavLink
										to="/contact"
										className="dropdown-toggle"
									>
										Contact
									</NavLink>
								</li>
							</ul>
						</nav>
						<nav className={extraNav}>
							<Link
								to="/sign-up"
								className="btn rounded-pill pxp-nav-btn"
								style={{fontSize:"12px"}}
							>
								S'inscrire
							</Link>
							<Link
								className="btn rounded-pill pxp-user-nav-trigger pxp-on-light"
								to="/login"
								style={{fontSize:"12px"}}
								// data-bs-toggle="modal"
								// to="#pxp-signin-modal"
								// role="button"
							>
								Se connecter
							</Link>
						</nav>
					</div>
				</div>
			</header>
		</>
	);
};

export default Header;
