import React, { useEffect, useState } from "react";
import { Input } from "../../components/inputDash/index";
import axios from "axios";
import SubmitButton from "../../components/submitButton/index";
import { Eggy } from '@s-r0/eggy-js';

import { useFormik } from "formik";

import * as Yup from "yup";

const Password = ({ data }) => {
	const [display, setDisplay] = useState(false);
	const { password, id, user } = data;

	const validationSchema = Yup.object({
		currentPassword: Yup.string()
			.min(3, "Must be greater then 3 characters")
			.required("Current Password is Required!"),
		newPassword: Yup.string().min(8).required("New Password is Required!"),
		confirmNewPassword: Yup.string().oneOf(
			[Yup.ref("newPassword"), null],
			"Password doesn't matched"
		),
	});
	const formik = useFormik({
		initialValues: {
			currentPassword: password,
			newPassword: "",
			confirmNewPassword: "",
		},
		validationSchema,
		onSubmit: (values) => {
			setDisplay(true);
			axios
				.put(
					`${process.env.REACT_APP_API_URL}/${user}/updatePassword/${id}`,
					{
						...values,
					}
				)
				.then(async(res) => {
					await Eggy({
						title: "Success",
						message: "profile updated !",
						type: 'success',
					});
					setDisplay(false);
					console.log(res?.data?.results);
				})
				.catch(async(err) => {
					await Eggy({
						title: "Error",
						message: "profile doesn't update !",
						type: 'error',
					});
					console.log(err);
				});
		},
	});

	useEffect(() => {}, [display]);
	return (
		<div
			style={{
				justifySelf: "center",
				padding: "100px 0px",
				width: "80%",
			}}
		>
			<div className="pxp-dashboard-content-details">
				<h1>Changer le mot de passe</h1>
				<p className="pxp-text-light">
					Choisissez un nouveau mot de passe de compte.
				</p>
				<form onSubmit={formik.handleSubmit}>
					<div className="row mt-4 mt-lg-5">
						<div className="col-xxl-8">
							<div className="row">
								<div className="col-sm-6">
									<Input
										id={"currentPassword"}
										title={"Ancien mot de passe"}
										type={"password"}
										formik={formik}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6">
									<Input
										id={"newPassword"}
										title={"Nouveau mot de passe"}
										type={"password"}
										formik={formik}
									/>
								</div>
								<div className="col-sm-6">
									<Input
										id={"confirmNewPassword"}
										title={
											"Répéter le nouveau mot de passe"
										}
										type={"password"}
										formik={formik}
									/>
								</div>
							</div>
						</div>
					</div>
					<SubmitButton display={display} />
				</form>
			</div>
		</div>
	);
};

export default Password;
