import React from "react";
// import Globe from "../../assets/images/Carte-Monde-HR-GLOBE.png";
import { COLORS } from "../../utils";

export default function ConditionsDescription() {
	return (
		<section
			className="pt-100 pb-100"
			style={{ backgroundColor: COLORS.customMainColorLight }}

		>
			<div
				style={{ width: "100%" }}
				className="row justify-content-center"
			>
				<div className="col-xl-7 col-xxl-6">
					<div className="mt-4 mt-md-5 text">

						<h3 style={{ color: "#2d2e83" }}>
							Conditions d'utilisation
						</h3>
					</div>
					<div className="mt-4 mt-md-5 text-left">
						{/* <h5 style={{ color: "#2d2e83" }}>
							Demande de Souscription
						</h5> */}
						<p>
							Les informations recueillies sur le site www.wselect.ca font l'objet d'un traitement destiné
							à vous inscrire sur sa plateforme et par conséquent vous donner la possibilité de postuler aux
							annonces des recruteurs.
							World select peut modifier les présentes conditions d'utilisation et, si les modifications apportées
							sont importantes, vous en informer via les services ou par d'autres moyens, pour vous donner
							l'opportunité d'examiner les modifications avant qu'elles ne prennent effet. Si vous vous opposez
							à l'une quelconque des modifications, vous pouvez prendre contact avec nous en envoyant un email à
							l'adresse info@wselect.ca
							Vous reconnaissez que si vous continuez à utiliser nos services après la publication des modifications
							apportées à nos conditions d'utilisation ou l'envoi d'un avis à ce sujet, cela signifie que la
							collecte, l'utilisation et le partage de vos données personnelles sont soumis à la version mise à
							jour de nos conditions d'utilisation, à compter de sa date d'entrée en vigueur.
						</p>

						<ul>
							<li>
								Votre inscription sur wselect.ca
							</li>
							<li>
								Conditions d'utilisation de votre espace personnel
							</li>
							<li>
								Vos droits
							</li>
							<li>
								Responsabilité,
							</li>
						</ul>
					</div>

					<div className="mt-4 mt-md-5 text-left">
						<h5 style={{ color: "#2d2e83" }}>
							Votre inscription sur wselect.ca
						</h5>
						<p>Pour créer un compte, vous devez indiquer votre nom et prénom, votre adresse e-mail, votre fonction, votre expérience ainsi qu'un mot de passe.
							wselect.ca vous permet de bénéficier de plusieurs services gratuits, vous avez la possibilité de vous inscrire à chaque service en cochant la case y correspondant à chaque étape de votre inscription. Vous pouvez aussi à tout moment vous désinscrire en décochant la même case sur votre espace personnel.
							Une fois votre inscription achevée, vous pouvez être contacté directement par les recruteurs intéressés par votre CV par leurs propres moyens de communication ou par l'interface de notre CV Thèque. World select veille à s'associer à des établissements sérieux, les destinataires des données sont tous des clients et partenaires au Maroc et à l'étranger.</p>
						<h6 style={{ color: "#2d2e83" }}>
							Gestion de vos préférences :
						</h6>
						<p>Nous pouvons vous envoyer des recommandations de postes par email, des messages promotionnels émis par nos équipes ou nos partenaires. Vous disposez d'un droit d'accès, de modification, de rectification et de suppression de vos préférences.
							World select vous offre aussi une visibilité de votre CV, cette option à cocher à la création de votre CV, vous permet de mettre en avant votre profil et de vous donner plus de chance d'être contacté par les recruteurs. </p>
						<p>Après avoir créé et déposé votre CV en ligne, vous verrez vos chances d'être contacté par différents type de professionnels augmenter. Pour ce faire, World select paramètre une alerte email basée sur votre profil, que vous recevrez à chaque diffusion d'offre d'emploi pouvant vous correspondre. Vous pouvez à tout moment désactiver cette alerte email en envoyant un email à info@wselect.ca
							Conformément à la loi 09-08 promulguée par le Dahir 1-09-15 du 18 Février 2009, et au règlement général sur protection des données (RGPD), vous bénéficiez d'un droit d'accès et de rectification aux informations qui vous concernent, que vous pouvez exercer en vous adressant à info@wselect.ca  Ces informations vous concernant étant confidentielles, World select s'engage à les protéger. Vous pouvez également, pour des motifs légitimes, vous opposer à ce que les données qui vous concernent fassent l'objet d'un traitement.
							Ce traitement a été notifié par la CNDP au titre de l'autorisation n° D-M-17/2013. Pour plus d'informations, vous pouvez consulter le site de la CNDP : www.cndp.ma</p>
						<h6 style={{ color: "#2d2e83" }}>
							Délai de conservation de vos données personnelles :
						</h6>
						<p>Votre Espace Personnel sur notre site reste à votre disposition aussi longtemps que cela peut vous être utile pendant votre vie professionnelle. A tout moment, vous pouvez actualiser, modifier ou supprimer votre compte de notre base de données en vous adressant à info@wselect.ca</p>
						<h6 style={{ color: "#2d2e83" }}>
							Votre profil sur Internet :</h6>
						<p>Lorsque vous créez votre CV sur World select, les moteurs de recherche comme Google passent régulièrement en revue le profil des membres de World select pour indexer les nouvelles informations ou les informations mises à jour, afin qu'elles puissent apparaître dans leurs résultats de recherche. Ceci indexe votre profil qui peut ainsi faire l'objet d'une recherche sur le web.
							Point d'attention :
							Nous n'avons aucun contrôle sur la vitesse à laquelle les moteurs de recherche effectuent leurs mises à jour après les modifications apportées à votre profil. Certains moteurs de recherche peuvent prendre plusieurs semaines à supprimer les anciens profils ou afficher les informations mises à jour.</p>

					</div>
					<div className="mt-4 mt-md-5 text-left">
						<h5 style={{ color: "#2d2e83" }}>
							Conditions d'utilisation de votre espace personnel
						</h5>
						<p>Une fois votre compte crée, Vous devez compléter les informations figurant sur votre profil, comme votre formation, votre expérience professionnelle, vos compétences, votre photo, votre ville/région ainsi que votre résumé de carrière. Ces informations vous permettent de tirer le meilleur parti de nos services, notamment de vous faire gagner en visibilité auprès des recruteurs et pour les opportunités d'emploi.
							Vous recevrez régulièrement un e-mail vous demandant de finaliser la création de votre compte ou le compléter. Pour accéder à votre Espace Personnel, vous possédez un</p>
						identifiant (votre e-mail) et un mot de passe que vous avez déterminés vous-même lors de la première saisie. En cas de perte de ces identifiant et mot de passe, vous pouvez les retrouver rapidement sur le site wselect.ca en cliquant sur le lien suivant : Mot de passe oublié
						<p>N'ajoutez ou ne saisissez sur votre profil que des données personnelles que vous acceptez de rendre publiques.</p>
						<h6 style={{ color: "#2d2e83" }}>
							Au moment de postuler à une annonce :</h6>
						<p>Si vous postulez à une annonce, votre CV World select, ainsi que tous les fichiers associés, seront automatiquement joints à votre candidature et seront visibles par le recruteur, dont vous trouverez les coordonnées sur la page de votre candidature.
							Vous disposez d'un droit d'accès, de modification, de rectification et de suppression des données qui vous concernent à tout moment. Vous pouvez exercer ce droit directement sur le site, dans votre espace perso, ou en vous adressant à info@wselect.ca
							Vous pouvez être invité, au moment de postuler à une annonce, à répondre à des questions posées par le recruteur. Ces questions peuvent être obligatoires et permettent au recruteur de compléter et d'affiner les informations concernant votre profil. Sachez néanmoins que toutes les informations communiquées lors de votre candidature seront traitées uniquement par le recruteur et ne seront utilisées que pour étudier la correspondance de votre profil au poste souhaité.</p>
					</div>
					<div className="mt-4 mt-md-5 text-left">
						<h5 style={{ color: "#2d2e83" }}>
							Vos Droits
						</h5>
						<p>Conformément à la loi 09-08 promulguée par le Dahir 1-09-15 du 18 Février 2009, et au règlement général sur la protection des données (RGPD), vous bénéficiez d'un droit d'accès et de rectification aux informations qui vous concernent. Pour exercer ce droit, plusieurs solutions s'offrent à vous :
							Vous connecter à votre  et procéder en ligne à toutes les modifications Si vous avez perdu vos identifiant et mot de passe, vous pouvez les demander en écrivant à info@wselect.ca , ou nous envoyer vos modifications à cette même adresse.</p>
					</div>
					<div className="mt-4 mt-md-5 text-left">
						<h5 style={{ color: "#2d2e83" }}>
							Responsabilité
						</h5>
						<p>
							World select s'engage à mettre en œuvre tous les moyens nécessaires afin d'assurer au mieux la fourniture des services qu'il propose à l'internaute. Sa responsabilité ne saurait être engagée en cas de force majeure ou de fait indépendant de sa volonté, notamment en cas d'interruption du service résultant d'une défaillance du réseau téléphonique ou du fournisseur d'accès à internet de l'internaute. World select n'est pas responsable de l'utilisation qui pourra être faite par l'internaute des informations et des services disponibles sur le site.
						</p>
					</div>
				</div>
			</div>
		</section>
)}                
