export default function animateOnMouseMove(element) {
	const mouseMoveElems = document.querySelectorAll(element);

	mouseMoveElems.forEach(function (mouseMoveElem) {
		var speed = mouseMoveElem.getAttribute("data-speed");

		window.addEventListener("mousemove", (evt) => {
			const x = -(window.innerWidth / 2 - evt.pageX) / parseInt(speed);
			const y = -(window.innerHeight / 2 - evt.pageY) / parseInt(speed);
			mouseMoveElem.style.transform = `translateY(${y}px) translateX(${x}px)`;
		});
	});
}
