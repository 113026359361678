import React, { Suspense } from "react";
import AboutHero from "../components/about/AboutHero";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Background from "../assets/images/Services-condidat.jpg";
import AboutCandidat from "../components/about/AboutCandidat";
import Pricing from "../components/Pricing";
// import Logo from "../assets/images/Logo-HR-Globe-Profil.png";
import Logo from "../assets/images/logo-transparant.png";
import PreLoader from "../components/PreLoader";

export default function Candidat() {
	return (
		<>
			<Suspense fallback={<PreLoader />}>
				<Header
					logo={Logo}
					header="pxp-header fixed-top"
					nav="pxp-nav dropdown-hover-all pxp-light d-none d-xl-block"
					extraNav="pxp-user-nav d-none d-sm-flex"
				/>
				<AboutHero
					bg={Background}
					content={() => (
						<div className="text-center">
							<h1
								style={{
									fontWeight: 50,
									fontSize: "48px",
									// fontFamily: "barlow",
									paddingTop: 10
								}}
							>
								Faites{" "}
								<b
									style={{
										// fontWeight: "bold",
										fontWeight: "normal",
										fontSize: "48px",
									}}
								>
									le tremplin{" "}
								</b>
								vers{" "}
								<b
									style={{
										// fontWeight: "bold",
										fontWeight: "normal",
										fontSize: "48px",
									}}
								>
									l'emploi{" "}
								</b>{" "}
								qui vous{" "}
								<b
									style={{
										// fontWeight: "bold",
										fontWeight: "normal",
										fontSize: "48px",
									}}
								>
									convient
								</b>
							</h1>
						</div>
					)}
				/>
				<AboutCandidat />
				<Pricing />
				<Footer />
			</Suspense>
		</>
	);
}

// todo
// make template layout to avoid importing components in evry new page
