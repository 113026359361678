import $ from "jquery";
import animateBubbles from "./animateBubbles";
import animateTestimonialsCircles from "./animateTestimonialsCircles";

export default function animateHTML() {
	var elems;
	var windowHeight;

	function init() {
		elems = document.querySelectorAll(".pxp-animate-in");
		windowHeight = window.innerHeight;
		addEventHandlers();
		checkPosition();
	}

	function addEventHandlers() {
		window.addEventListener("scroll", checkPosition);
		window.addEventListener("resize", init);
	}

	function checkPosition() {
		for (var i = 0; i < elems.length; i++) {
			var positionFromTop = elems[i].getBoundingClientRect().top;

			if (positionFromTop - windowHeight <= 0) {
				elems[i].classList.add("pxp-in");
				if ($(elems[i]).hasClass("pxp-info-stats-item")) {
					animateBubbles(elems[i]);
				}
				if ($(elems[i]).hasClass("pxp-testimonials-1-circles-item")) {
					animateTestimonialsCircles(elems[i]);
				}
			}
		}
	}

	return {
		init: init,
	};
}
