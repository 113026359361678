import React from "react";
import Globe from "../../assets/images/Carte-Monde-HR-GLOBE.png";

export default function AboutCandidat() {
	return (
		<section
			className="pt-100 pb-100"
			style={{
				backgroundImage: `url(${Globe})`,
				backgroundPositionY: " -100px",
			}}
		>
			<div
				style={{ width: "100%" }}
				className="row justify-content-center"
			>
				<div className="col-xl-7 col-xxl-6">
					<div className="mt-4 mt-md-5 text-center">
						<p>
							Convaincus qu'il y a pour chaque poste qu'une
							embauche à la clé. Nos équipes vont déployer toutes
							les solutions adaptées pour vous garantir le
							meilleur choix de carrières auprès de nos
							partenaires. Ceci dit, vous bénéficierez des
							opportunités de carrière à l'échelle internationale,
							et bien plus encore !
						</p>
						<p>
							<b>
								Vous bénéficierez également à un accès à des
								emplois ciblés qui correspondent à votre profil,
								et ceci grâce aux différents packs proposés.
							</b>
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}
