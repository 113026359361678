import React from "react";
import { Link } from "react-router-dom";

const NavBar = () => {

    return (
        <div style={{ position: "fixed", top: "20px", right: "90px" }}>
            <nav className="pxp-user-nav pxp-on-light">
                {/* <Link
                    to="company-dashboard-new-job.html"
                    className="btn rounded-pill pxp-nav-btn"
                >
                    Post a Job
                </Link> */}
                {/* <div className="dropdown pxp-user-nav-dropdown pxp-user-notifications">
                    <Link
                        role="button"
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        to={"#"}
                    >
                        <span className="fa fa-bell-o"></span>
                        <div className="pxp-user-notifications-counter">
                            5
                        </div>
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-end">
                        <li>
                            <Link
                                className="dropdown-item"
                                to="company-dashboard-notifications.html"
                            >
                                <strong>Scott Goodwin</strong> applied for{" "}
                                <strong>Software Engineer</strong>.{" "}
                                <span className="pxp-is-time">20m</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="dropdown-item"
                                to="company-dashboard-notifications.html"
                            >
                                <strong>Alayna Becker</strong> sent you a
                                message.{" "}
                                <span className="pxp-is-time">1h</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="dropdown-item"
                                to="company-dashboard-notifications.html"
                            >
                                <strong>Erika Tillman</strong> applied for{" "}
                                <strong>Team Leader</strong>.{" "}
                                <span className="pxp-is-time">2h</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="dropdown-item"
                                to="company-dashboard-notifications.html"
                            >
                                <strong>Scott Goodwin</strong> applied for{" "}
                                <strong>Software Engineer</strong>.{" "}
                                <span className="pxp-is-time">5h</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="dropdown-item"
                                to="company-dashboard-notifications.html"
                            >
                                <strong>Alayna Becker</strong> sent you a
                                message.{" "}
                                <span className="pxp-is-time">1d</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="dropdown-item"
                                to="company-dashboard-notifications.html"
                            >
                                <strong>Erika Tillman</strong> applied for{" "}
                                <strong>Software Engineer</strong>.{" "}
                                <span className="pxp-is-time">3d</span>
                            </Link>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <li>
                            <Link
                                className="dropdown-item pxp-link"
                                to="company-dashboard-notifications.html"
                            >
                                Read All
                            </Link>
                        </li>
                    </ul>
                </div> */}
                <div className="dropdown pxp-user-nav-dropdown">
                    <Link
                        role="button"
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        to={"#"}
                    >
                        <div
                            className="pxp-user-nav-avatar pxp-cover"
                        // style={{
                        // 	backgroundImage: `url(${ComLogo})`,
                        // }}
                        // {/* ahaloua */}
                        ></div>
                        <div className="pxp-user-nav-name d-none d-md-block">
                        Settings
                        </div>
                    </Link>
                    <ul className="dropdown-menu dropdown-menu-end">
                        {/* <li>
                            <Link
                                className="dropdown-item"
                                to="company-dashboard.html"
                            >
                                Dashboard
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="dropdown-item"
                                to="company-dashboard-profile.html"
                            >
                                Edit profile
                            </Link>
                        </li> */}
                        <li>
                            <Link className="dropdown-item" to="/login" onClick={()=>{
                                			localStorage.clear("token");

                            }}>
                                Logout
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    );
}

export default NavBar;
