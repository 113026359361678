import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import onContentScroll from "../../../lib/main/onContentScroll";
import handlePreloader from "../../../lib/main/handlePreloader";
import windowResizeHandler from "../../../lib/main/windowResizeHandler";
// import ComLogo from "../../../assets/images/company-logo-1.png";
import $ from "jquery";
import Logo from "../../../assets/images/logo-transparant.png";

const SidePanel = ({ position, setPosition, panel }) => {
	useEffect(() => {
		window.onscroll = function () {
			onContentScroll();
		};

		handlePreloader();

		windowResizeHandler();

		$(window).resize(function () {
			windowResizeHandler();
		});

		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="pxp-dashboard-side-panel d-block">
			<div className="pxp-logo">
				<Link to="/" className="pxp-animate">
					<img style={{ height: "37px" }} src={Logo} alt="logo" />
				</Link>
			</div>
			<nav className="mt-3 mt-lg-4 d-flex justify-content-between flex-column pb-100">
				<div className="pxp-dashboard-side-label"></div>
				<ul className="list-unstyled">
					{panel.map((elem, index) => {
						const temp = index === position ? "pxp-active" : "";
						return (
							<li
								style={{
									opacity: elem?.status === 0 ? "0.4" : "1",
								}}
								key={index}
								className={temp}
							>
								<Link
									onClick={() => {
										elem?.status === 1 &&
											setPosition(index);
									}}
									to={""}
								>
									<span className={elem.spanClass}></span>
									{elem.title}
								</Link>
							</li>
						);
					})}
				</ul>
				<div className="pxp-dashboard-side-label mt-3 mt-lg-4">
					Insights
				</div>
				<ul className="list-unstyled">
					<li>
						<Link
							onClick={() => {
								setPosition(20);
							}}
							to={""}
							className="d-flex justify-content-between align-items-center"
						>
							<div>
								<span className="fa fa-envelope-o"></span>
								Inbox
							</div>
							{/* <span className="badge rounded-pill">14</span> */}
						</Link>
					</li>
				</ul>
			</nav>

			{/* <nav 
				className="pxp-dashboard-side-user-nav-container"
				style={{
					backgroundColor: "#fbf2f2",
				}}
			>
				{/* <div className="pxp-dashboard-side-user-nav">
					<div className="dropdown pxp-dashboard-side-user-nav-dropdown dropup">
						<Link
							role="button"
							className="dropdown-toggle"
							data-bs-toggle="dropdown"
							to={"#"}
						>
							<div
								className="pxp-dashboard-side-user-nav-avatar pxp-cover"
								style={{
									backgroundImage: `url(${ComLogo})`,
								}}
							// style="background-image: url(images/company-logo-1.png);"
							></div>
							<div className="pxp-dashboard-side-user-nav-name">
								Artistre Studio
							</div>
						</Link>
						<ul className="dropdown-menu">
							<li>
								<Link
									className="dropdown-item"
									to={"company-dashboard.html"}
								>
									Dashboard
								</Link>
							</li>
							<li>
								<Link
									className="dropdown-item"
									to={"company-dashboard-profile.html"}
								>
									Edit profile
								</Link>
							</li>
							<li>
								<Link
									className="dropdown-item"
									to={"index.html"}
								>
									Logout
								</Link>
							</li>
						</ul>
					</div>
				</div> 
			</nav> */}
		</div>
	);
};

export default SidePanel;
