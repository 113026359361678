/* eslint-disable no-unreachable */
import React from "react";
const RowTable = ({Row, Key}) => {
    console.log(Row)
    return (
        <tr key={`RowTable${Key}`} >
            {/* <td><input type="checkbox" className="form-check-input" /></td> */}
            <td key={`1RowTable${Key}`}>
                <a href={Row?.offerLink}>
                    <div className="pxp-candidate-dashboard-job-title">{Row?.job}</div>
                    <div className="pxp-candidate-dashboard-job-location">
                        <span className="fa fa-globe me-1"></span>{Row?.country}, {Row?.city}</div>
                </a>
            </td>
            <td key={`2RowTable${Key}`}><a href={Row?.companyLink} className="pxp-candidate-dashboard-job-company">{Row?.company}</a></td>
            <td key={`3RowTable${Key}`}><div className="pxp-candidate-dashboard-job-category">{Row?.category}</div></td>
            <td key={`4RowTable${Key}`}><div className="pxp-candidate-dashboard-job-type">{Row?.type}</div></td>
            <td key={`5RowTable${Key}`}><div className="pxp-candidate-dashboard-job-date mt-1">{Row?.date}</div></td>
        </tr>

    )
}

export default RowTable;