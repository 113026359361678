/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
import { instance } from "../../lib/axios";
import { useParams } from 'react-router-dom';
import relativeTime from 'dayjs/plugin/relativeTime';
import { AuthContext } from "../../context/Auth";

import HeaderTable from "../../components/tableCandidateInOffer/headerTable.js";
import { BodyTAble } from "../../components/tableCandidateInOffer/bodyTable.js";
import NextPageList from "../../components/tableCandidateInOffer/nextPage.js";
const candidateInOfferTitels = [
	{ with: "25%", title: "nom et prenom" },
	{ with: "25%", title: "email" },
    { with: "25%", title: "telephone" },
	{ with: "25%", title: "diplom" },
	{ with: "25%", title: "domaineDactivite" },
];




const CandidatesInOffer = () => {
    dayjs.extend(relativeTime)

    let { id } = useParams();
    const [data, setData] = useState([])
    useEffect(() => {
        const queryParams = {
            sort: "desc",
            skip: page,
            take: rowslenght,
        };
        instance.get(`/candidate-apply/offerId/${id}`, {queryParams}).then((res) => {
            console.log("blabla ", res?.data?.results)

            const temp = res?.data?.results;
            // console.log(temp)
            setData(temp)
        }).catch((err) => { console.log(err) });

    }, [])

	const [page, setPage] = useState(0);
	const [rowslenght, setRowslenght] = useState(5);


    return (
        <>
            {/* <section className="pxp-single-company-hero pxp-cover" style={{ backgroundImage: "url(images/ph-big.jpg);" }}>
                <div className="pxp-hero-opacity"></div>
                <div className="pxp-single-company-hero-caption">
                    <div className="pxp-container">
                        <div className="pxp-single-company-hero-content">
                            <div className="pxp-single-company-hero-logo" style={{ backgroundImage: "url(images/company-logo-1.png);" }}></div>
                            <div className="pxp-single-company-hero-title">
                                <h1>{data?.company}</h1>
                                <div className="pxp-single-company-hero-location"><span className="fa fa-globe"></span>{data?.city} - {data?.country}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section>
            <div
			style={{
				justifySelf: "center",
				padding: "100px 10% 0px",
				width: "80%",
			}}
		>
			<div className="pxp-dashboard-content-details">
				<h1>Candidates postuler</h1>
				<div className="mt-4 mt-lg-5">
					<div className="row justify-content-between align-content-center">
						<div className="col-auto order-1 order-sm-2">
							<div className="pxp-candidate-dashboard-jobs-search mb-3"></div>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-hover align-middle">
							<HeaderTable titels={candidateInOfferTitels} />
							<BodyTAble data={data} />
						</table>
						<NextPageList
							setPage={setPage}
							page={page}
							rowslenght={rowslenght}
							setRowslenght={setRowslenght}
						/>
					</div>
				</div>
			</div>
		</div>
            </section> </>
    )
}

export default CandidatesInOffer;