import React from "react";

const HeaderTable = ({ titels }) => {
    return (
        <thead>
            <tr>
                {titels?.map((elem, index ) => { return (<th key={index} style={{ width: elem?.width }}>{elem?.title}</th>) })}
                {/* <th>&nbsp;</th> */}
            </tr>
        </thead>
    )
}

export default HeaderTable;