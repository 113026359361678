
/* eslint-disable no-unreachable */
import React, {useState, useEffect} from "react";
import { instance } from "../../../lib/axios";
import { useNavigate } from 'react-router-dom';

const OfferRowTable = ({ Row }) => {

    const [numbOfApplayers, setNumbOfApplayers] = useState(null);
	useEffect(() => {
		instance
			.get(`/candidate-apply/offerId/${Row?.id}`)
			.then((res) => {
				setNumbOfApplayers(res?.data?.results?.length);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);


    const navigate = useNavigate();
    const HandelView = () => {
        navigate(`/annonce/${Row?.id}`)
    }
    const HandelDelete = () => {
        instance.delete(`/annonces/${Row?.id}`).then((res) => {
            console.log("done delete");
        }).catch((err) => { console.log(err) });
    }
    const HandelEdit = () => {
        navigate(`/updateAnnonce/${Row?.id}`)
        console.log("test offer row table")
    }
    const HandelListCandidate  = () => {
        navigate(`/candidatesInOffer/${Row?.id}`)
    }

    return (
        <tr>
            <td>
                <a href={Row?.offerLink}>
                    <div className="pxp-candidate-dashboard-job-title">{Row?.job}</div>
                    <div className="pxp-candidate-dashboard-job-location">
                        <span className="fa fa-globe me-1"></span>{Row?.country}, {Row?.city}</div>
                </a>
            </td>
            <td><div className="pxp-candidate-dashboard-job-category">{Row?.category}</div></td>
            <td><div className="pxp-candidate-dashboard-job-type">{Row?.type}</div></td>
            <td><div className="pxp-candidate-dashboard-job-date mt-1">{Row?.status}</div></td>

            <td><div className="pxp-candidate-dashboard-job-date mt-1">{Row?.date}</div></td>
            <td><a onClick={HandelListCandidate} className="pxp-candidate-dashboard-job-date mt-1">{`${numbOfApplayers} candidate`}</a></td>
            <td>
                <div className="pxp-dashboard-table-options">
                    <ul className="list-unstyled">
                        <li><button title="Edit"><span className="fa fa-pencil" onClick={HandelEdit}></span></button></li>
                        <li><button title="Preview"><span className="fa fa-eye" onClick={HandelView}></span></button></li>
                        <li><button title="Delete"><span className="fa fa-trash-o" onClick={HandelDelete} ></span></button></li>
                    </ul>
                  </div>
            </td>   </tr>

    )
}

export default OfferRowTable;
