import $ from "jquery";

export default function onContentScroll() {
	if (
		$(".pxp-header").hasClass("pxp-bigger") ||
		$(".pxp-header").hasClass("pxp-no-bg")
	) {
		if (window.pageYOffset > 20) {
			$(".pxp-header").addClass("pxp-is-sticky");
		} else {
			$(".pxp-header").removeClass("pxp-is-sticky");
		}
	} else if ($(".pxp-header").hasClass("pxp-no-bg")) {
		if (window.pageYOffset > 0) {
			$(".pxp-header").addClass("pxp-is-sticky");
		} else {
			$(".pxp-header").removeClass("pxp-is-sticky");
		}
	} else {
		if (window.pageYOffset > 93) {
			$(".pxp-header").addClass("pxp-is-sticky");
		} else {
			$(".pxp-header").removeClass("pxp-is-sticky");
		}
	}
}
