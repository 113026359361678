import React from "react";
import Globe from "../../assets/images/Carte-Monde-HR-GLOBE.png";

export default function AboutEmployer() {
	return (
		<section
			className="pt-100 pb-100"
			style={{
				backgroundImage: `url(${Globe})`,
				backgroundPositionY: " -100px",
			}}
		>
			<div
				style={{ width: "100%" }}
				className="row justify-content-center"
			>
				<div className="col-xl-7 col-xxl-6">
					<div className="mt-4 mt-md-5 text-center">
						<p>
							Soucieux de la complexité de la démarche et des
							procédures de recrutement chronophage,{" "}
							<b>World select</b> a pour mission d'accompagner et
							d'assurer les projets de recrutements des
							entreprises clientes afin que ces dernières puissent
							se concentrer sur leur cœur de métier.
						</p>
						<p>
							<b>
								Nos experts ne ménageront aucun effort pour vous
								trouver la perle rare adaptée à chaque poste à
								pourvoir et vous accompagner dans le recrutement
								international.
							</b>
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}
