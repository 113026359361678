const COLORS = {
	// text: "black",
	// background: "white",
	// primary: "rebeccapurple",
	pxpHoverTransition: "300ms cubic-bezier(0.215, 0.61, 0.355, 1)",
	pxpHoverOpacity: "0.6",
	pxpTextColor: "#17171d",
	// pxpMainColor: "#a61816",
	pxpMainColor: "#fdc800",
	pxpMainColorTransparent: "rgba(9, 105, 195, 0.05)",
	pxpMainColorDark: "#002745",
	pxpMainColorLight: "rgb(237 228 228)",
	pxpSecondaryColor: "#fff0d9",
	pxpSecondaryColorLight: "#fff8ec",
	pxpLightGrayColor: "#dedede",
	pxpDarkGrayColor: "#999",

	customMainColorLight: "#009fe30a",
	// customMainColorLight: "#fdc8000f",
};

const SIZES = [8, 16, 24, 32];

export { COLORS, SIZES };
