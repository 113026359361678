import React from "react";

export const InputSearch = ({ title, id, formik }) => {
    return (
        <div className="col-12 col-lg">
            <div className="input-group mb-3 mb-lg-0">
                <span className="input-group-text">
                    <span
                        className="fa fa-search"></span>
                </span>
                <input
                    id={id}
                    type="text"
                    className="form-control"
                    placeholder={title}
                    value={formik?.values[id]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name={id}                  
                    style={{ border: "none" }} />
            </div>
        </div>
    );
}

export const SelectSearch = ({ title, id, list, formik }) => {
    return (
        <div className="col-12 col-lg pxp-has-left-border">
            <div className="input-group mb-3 mb-lg-0">
                <span className="input-group-text"><span className="fa fa-folder-o"></span></span>
                <select className="form-select" style={{ border: "none" }}
                    value={formik?.values[id] || "DEFAULT"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name={id}>
                    <option disabled value={"DEFAULT"}>{title}</option>
                    {list?.map((elem, index) => { return (<option key={`${id}${index}`} value={elem?.id} >{elem?.name}</option>) })}
                </select>
            </div>
        </div>

    );
}


export const Textarea = ({ title, id, formik }) => {
    const placeholder = `Type the ${title} here...`

    return (
        <div className="mb-3">
            <label
                htmlFor={id}
                className="form-label">
                {title}
            </label>
            <textarea
                className="form-control"
                id={id}
                value={formik?.values[id]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name={id}
                placeholder={placeholder}></textarea>
        </div>
    )
}
