import $ from "jquery";

export default function animateHeroElement(element) {
	if ($(element).hasClass("pxp-has-animation")) {
		$(element).addClass("pxp-animate");
	}
	if ($(element).hasClass("pxp-animate-cards")) {
		setTimeout(function () {
			$(element).find(".pxp-hero-card").addClass("pxp-animate");
		}, 600);
		setTimeout(function () {
			$(element).find(".pxp-hero-card-dark").addClass("pxp-animate");
			$(element).find(".pxp-hero-card-light").addClass("pxp-animate");
		}, 1200);
	}
	if ($(element).hasClass("pxp-animate-bounce")) {
		setTimeout(function () {
			$(element).addClass("animate__animated animate__bounceIn");
		}, 1800);
	}
	if ($(element).hasClass("pxp-animate-circles-bounce")) {
		$(element).addClass("animate__animated animate__bounceIn");
	}
	if ($(element).hasClass("pxp-animate-info-card")) {
		setTimeout(function () {
			$(element).addClass("animate__animated animate__flipInX");
		}, 300);
	}
	if ($(element).hasClass("pxp-animate-icon-circle-bounce")) {
		setTimeout(function () {
			$(element).addClass("animate__animated animate__bounceIn");
		}, 800);
	}
}
