import React, { Suspense } from "react";
// import Logo from "../assets/images/Logo-HR-Globe-Profil.png";
import Logo from "../assets/images/logo-transparant.png";
import PreLoader from "../components/PreLoader";

const Header = React.lazy(() => import("../components/layout/Header"));
const Footer = React.lazy(() => import("../components/layout/Footer"));
const AddEmployerFrom = React.lazy(() =>
	import("../components/forms/AddEmployer2Form")
);

export default function AddEmployerForm() {
	return (
		<>
			<Suspense fallback={<PreLoader />}>
				<Header
					logo={Logo}
					header="pxp-header fixed-top pxp-no-bg pxp-has-border"
					nav="pxp-nav dropdown-hover-all d-none d-xl-block"
					extraNav="pxp-user-nav d-none d-sm-flex pxp-on-light"
				/>
				<AddEmployerFrom />
				<Footer />
			</Suspense>
		</>
	);
}
