/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import Content from "../../components/layout/Dashboard/Content";
import SidePanel from "../../components/layout/Dashboard/SidePanel";
import EmployerPtofile from "../../pages/Dashboard/Employerprofile";
import ManageAnnoncesList from "./ManageAnnoncesList";
import { CreatOffer } from "../Dashboard/offerJob";
import Password from "../../pages/Dashboard/password";
import NavBar from "../../components/layout/Dashboard/navBar";
import { instance } from "../../lib/axios";
import { AuthContext } from "../../context/Auth";
import Inbox from "../../pages/inbox";
import { useLocation } from "react-router-dom";

const EmployerDetails = ({ id}) => {
	const location = useLocation();
	const { auth } = useContext(AuthContext);
	const user = auth?.user;
	const [position, setPosition] = useState(location?.state === 20 ? 20 : 1);
	const [profile, setProfile] = useState(user);

	const [password, setPassword] = useState("");

	const employerId = id;
	useEffect(() => {
		instance
			.get(`/employers/${employerId}`)
			.then((res) => {
				const data = res?.data?.results;
				setProfile({
					contactName: data?.contactName,
					companieId: data?.companieId,
					contactPhone: data?.contactPhone,
					address: data?.address,
					email: data?.email,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const panelEmployer = [
		{ spanClass: "fa fa-home", title: "Dashboard", status: 1 },
		{ spanClass: "fa fa-pencil", title: "Edit Profile", status: 1 },
		{
			spanClass: "fa fa-file-text-o",
			title: "New Job Offer",
			status: profile?.companieId ? 1 : 0,
		},
		{ spanClass: "fa fa-lock", title: "Change Password", status: 1 },
		{
			spanClass: "fa fa-home",
			title: "Annonces",
			status: profile?.companieId ? 1 : 0,
		},
	];

	useEffect(() => {}, [profile, password]);
console.log("***********", profile);
	return (
		<div
			style={{
				// backgroundColor: "#a618160f",
				backgroundColor: "#e6f0f9",
				display: "flex",
				flexDirection: "row",
				height: "100%",
			}}
		>
			<SidePanel
				position={position}
				setPosition={setPosition}
				panel={panelEmployer}
			/>
			<NavBar />
			{position === 0 ? (
				<Content />
			) : position === 1 ? (
				<EmployerPtofile data={{ profile: profile, id: employerId }} />
			) : position === 2 ? (
				<CreatOffer
					data={{ id: employerId, companieId: profile?.companieId }}
				/>
			) : position === 3 ? (
				<Password
					data={{ password, id: employerId, user: "employers" }}
				/>
			) : position === 4 ? (
				<ManageAnnoncesList data={{ id: employerId }} />
			) : position === 20 ? (
				<Inbox />
			) : (
				<>ok</>
			)}
		</div>
	);
};

export default EmployerDetails;
