import React from "react";

export const Items2 = ({label, title}) => {
    return (

        <div className="mt-4">
            <div className="pxp-single-company-side-info-label pxp-text-light">{label}</div>
            <div className="pxp-single-company-side-info-data">{title}</div>
        </div>
    )
}