import React from "react";
import { Link } from "react-router-dom";

export default function Pricing() {
	return (
		<section className="mt-100" style={{ marginBottom: "100px" }}>
			<div className="pxp-container">
				<h2 className="pxp-section-h2">Packs Carrières</h2>
				<p className="pxp-text-light">Sélectionner votre plan</p>
				<div
					className="row mt-3 mt-md-4 pxp-animate-in pxp-animate-in-top pxp-in"
					style={{ justifyContent: "center" }}
				>
					<div className="col-md-6 col-xl-4 col-xxl-3 pxp-plans-card-1-container">
						<div className="pxp-plans-card-1"
							style={{ background: "#8fd5f3" }}>
							<div className="pxp-plans-card-1-top">
								<div className="pxp-plans-card-1-title">
									Pack Basic
								</div>
								<div className="pxp-plans-card-1-price">
									<div
										className="pxp-plans-price-monthly"
										style={{ display: "block", color: "#2d2e83" }}
									>
										$0
										<span className="pxp-period">
											/Mois
										</span>
									</div>
								</div>
								<div className="pxp-plans-card-1-list">
									<ul className="list-unstyled">
										<li>
											<span
												style={{
													fontSize: "24px",
													paddingRight: "12px",
												}}
												className="fa fa-check"
											></span>
											Inscription en ligne
										</li>
										<li>
											<span
												style={{
													fontSize: "24px",
													paddingRight: "12px",
												}}
												className="fa fa-check"
											></span>
											Création de profil
										</li>
										<li>
											<span
												style={{
													fontSize: "24px",
													paddingRight: "12px",
												}}
												className="fa fa-check"
											></span>
											Téléchargement CV
										</li>
										<li>
											<span
												style={{
													fontSize: "24px",
													paddingRight: "12px",
												}}
												className="fa fa-check"
											></span>
											Consultation des offres d'emploi
										</li>
									</ul>
								</div>
							</div>
							<div className="pxp-plans-card-1-bottom">
								<div className="pxp-plans-card-1-cta">
									<Link
										to="/candidate-sign-up"
										className="btn rounded-pill pxp-card-btn"
									>
										S'inscrire
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-xl-4 col-xxl-3 pxp-plans-card-1-container" >
						<div className="pxp-plans-card-1" style={{ background: "rgba(0, 159, 227, 0.72)" }}>
							<div className="pxp-plans-card-1-top">
								<div className="pxp-plans-card-1-title">
									Pack Premium
								</div>
								<div className="pxp-plans-card-1-price" >
									<div
										className="pxp-plans-price-monthly title-1"
										style={{ display: "block", color: "#2d2e83" }}
									>
										$20
										<span className="pxp-period">
											/Mois
										</span>
									</div>
								</div>
								<div className="pxp-plans-card-1-list">
									<ul className="list-unstyled">
										<li>
											<span
												style={{
													fontSize: "24px",
													paddingRight: "12px",
												}}
												className="fa fa-check"
											></span>
											Inscription en ligne
										</li>
										<li>
											<span
												style={{
													fontSize: "24px",
													paddingRight: "12px",
												}}
												className="fa fa-check"
											></span>
											Création de profil
										</li>
										<li>
											<span
												style={{
													fontSize: "24px",
													paddingRight: "12px",
												}}
												className="fa fa-check"
											></span>
											Téléchargement CV
										</li>
										<li>
											<span
												style={{
													fontSize: "24px",
													paddingRight: "12px",
												}}
												className="fa fa-check"
											></span>
											Consultation Offres d’emploi
										</li>
									</ul>
								</div>
							</div>
							<div className="pxp-plans-card-1-bottom">
								<div className="pxp-plans-card-1-cta">
									<Link
										to="/contact"
										className="btn rounded-pill pxp-card-btn"
									>
										En savoir plus
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-xl-4 col-xxl-3 pxp-plans-card-1-container">
						<div className="pxp-plans-card-1 pxp-is-featured">
							<div className="pxp-plans-card-1-top">
								<div className="pxp-plans-card-1-featured-label" style={{ background: "#8fd5f3" }}>
									Recommandé
								</div>
								<div className="pxp-plans-card-1-title" style={{color: "black"}}>
									Pack Gold
								</div>
								<div className="pxp-plans-card-1-price">
									<div
										className="pxp-plans-price-annual"
										style={{ display: "block", color: "#2d2e83" }}
									>
										$99
										<span className="pxp-period">
											/Mois
										</span>
									</div>
								</div>
								<div className="pxp-plans-card-1-list">
									<ul className="list-unstyled" >
										<li style={{color: "black"}}>
											<span
												style={{
													fontSize: "24px",
													paddingRight: "12px",
													color:"black",
												}}
												className="fa fa-check"
											></span>
											Inscription en ligne
										</li>
										<li style={{color: "black"}}>
											<span
												style={{
													fontSize: "24px",
													paddingRight: "12px",
													color:"black",
												}}
												className="fa fa-check"
											></span>
											Création de profil
										</li>
										<li style={{color: "black"}}>
											<span
												style={{
													fontSize: "24px",
													paddingRight: "12px",
													color:"black",
												}}
												className="fa fa-check"
											></span>
											Téléchargement CV
										</li>
										<li style={{color: "black"}}>
											<span
												style={{
													fontSize: "24px",
													paddingRight: "12px",
													color:"black",
												}}
												className="fa fa-check"
											></span>
											Consultation Offres d’emploi
										</li>
										<li style={{color: "black"}}>
											<span
												style={{
													fontSize: "24px",
													paddingRight: "12px",
													color:"black",
												}}
												className="fa fa-check"
											></span>
											Dépôt de Candidature (Postuler en
											ligne)
										</li>
									</ul>
								</div>
							</div>
							<div className="pxp-plans-card-1-bottom">
								<div className="pxp-plans-card-1-cta">
									<Link
										to="/contact"
										className="btn rounded-pill pxp-card-btn"
									>
										En savoir plus
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
