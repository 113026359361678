import React, { useEffect, useState } from "react";
import { Input } from "../../components/inputDash/index";
import axios from "axios";
import SubmitButton from "../../components/submitButton/index";
import { Eggy } from '@s-r0/eggy-js';

import { useFormik } from "formik";
import * as Yup from "yup";

const Employerprofile = ({ data }) => {
	const { profile, id } = data;
	// const [employer, setemployer] = useState([]);
	const [display, setDisplay] = useState(false);

	useEffect(() => {}, [id]);

	const validationSchema = Yup.object({
		contactName: Yup.string(),
		contactPhone: Yup.string(),
		address: Yup.string(),
		email: Yup.string(),
	});

	const formik = useFormik({
		initialValues: {
			contactName: profile?.contactName,
			contactPhone: profile?.contactPhone,
			address: profile?.address,
			email: profile?.email,
		},
		validationSchema,
		onSubmit: (values) => {
			setDisplay(true);
			axios
				.put(`${process.env.REACT_APP_API_URL}/employers/${id}`, {
					...values,
				})
				.then(async(res) => {
					await Eggy({
						title: "Success",
						message: "profile updated !",
						type: 'success',
					});
					setDisplay(false);
				})
				.catch(async(err) => {
					await Eggy({
						title: "Error",
						message: "profile doesn't update !",
						type: 'error',
					});
					console.log(err);
				});
		},
	});
	return (
		<div
			style={{
				justifySelf: "center",
				padding: "100px 0px",
				width: "80%",
			}}
		>
			<div
				className="pxp-dashboard-content-details"
			>
				<h1>Editer le profil</h1>
				<p className="pxp-text-light">
					Modifiez les informations de votre page de profil de
					employer.
				</p>
				<form onSubmit={formik.handleSubmit}>
					<div className="row mt-4 mt-lg-5">
						<div className="col-xxl-8">
							<div className="row">
							<div className="col-sm-6">

								<Input
									formik={formik}
									title={"Nom"}
									id={"contactName"}
								/>
															</div>

							</div>
							<div className="row">
								<div className="col-sm-6">
									<Input
										formik={formik}
										title={"Email"}
										id={"email"}
									/>
								</div>
								<div className="col-sm-6">
									<Input
										formik={formik}
										title={"Phone"}
										id={"contactPhone"}
									/>
								</div>
							</div>
							<Input
								formik={formik}
								title="Address"
								id="address"
							/>
						</div>
					</div>
					<SubmitButton display={display} />
				</form>
			</div>
		</div>
	);
};

export default Employerprofile;
