import React from "react";


const SubmitButton = ({ display }) => {

    return (
        <div className="mt-4 mt-lg-5">
            <button
                disabled={display}
                type="submit"
                className="btn rounded-pill pxp-section-cta">Enregistrer</button>
        </div>
    );
}

export default SubmitButton;
