/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Input, Select, Textarea } from "../../components/inputDash/index";
import { instance } from "../../lib/axios";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import SubmitButton from "../../components/submitButton/index";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useParams } from 'react-router-dom';


const UpdateAnnonce = () => {
    let { id } = useParams();
    const navigate = useNavigate();

    // const companieId = 1;
    // const [employer, setemployer] = useState([]);
    const [display, setDisplay] = useState(false);

    const [carrerLevel, setCarrerLevel] = useState([]);

    const [employmentType, setEmploymentType] = useState([]);
    const [salaryRange, setSalaryRange] = useState([]);
    const [domaines, setDomaineDactivite] = useState([]);
    const [country, setCountries] = useState([]);
    const [city, setCity] = useState([]);
    const [offer, setOffer] = useState([])
    const [initialValues, setInitialValues] = useState({
        name: "",
        discription: "",
        carrerLevelId: 0,
        employmentTypeId: 0,
        salaryRangeId: 0,
        yearsExp: "",
        domaineDactiviteId: 0,
        // companieId: companieId,
        employerId: id,
        countryId: 0,
        cityId: 0,
    })
    const years = [
        { name: "1", id: "one" },
        { name: "2", id: "two" },
        { name: "3", id: "three" },
        { name: "4", id: "four" },
        { name: "5", id: "five" },
        { name: "6", id: "six" },
        { name: "7", id: "seven" },
        { name: "8", id: "eight" },
        { name: "9", id: "nine" },
        { name: "10", id: "ten" }]

    useEffect(() => {
        instance.get(`/annonces/${id}`).then((res) => {
            const data = res?.data?.results;
            setOffer(data)
            setInitialValues({
                name: data?.name,
                discription: data?.discription,
                carrerLevelId: data?.carrerLevelId,
                employmentTypeId: data?.employmentTypeId,
                salaryRangeId: data?.salaryRangeId,
                yearsExp: data?.yearsExp,
                domaineDactiviteId: data?.domaineDactiviteId,
                employerId: data?.employerId,
                countryId: data?.countryId,
                cityId: data?.cityId,
            })

        }).catch((err) => { console.log(err) });
        instance.get(`/carrer-levels`).then((res) => {
            const data = res?.data?.results;
            setCarrerLevel(data)
        }).catch((err) => { console.log(err) });
        instance.get(`/employer-types`).then((res) => {
            const data = res?.data?.results;
            setEmploymentType(data)
        }).catch((err) => { console.log(err) });
        instance.get(`/salary-ranges`).then((res) => {
            const data = res?.data?.results;
            setSalaryRange(data)
        }).catch((err) => { console.log(err) });
        instance.get(`/domaines`).then((res) => {
            const data = res?.data?.results;
            setDomaineDactivite(data)
        }).catch((err) => { console.log(err) });
        instance.get(`/countries`).then((res) => {
            const data = res?.data?.results;
            setCountries(data)
        }).catch((err) => { console.log(err) });
        instance.get(`/cites`).then((res) => {
            const data = res?.data?.results;
            setCity(data)
        }).catch((err) => { console.log(err) });
    }, [])

    const validationSchema = Yup.object({
        name: Yup.string(),
        discription: Yup.string(),
        carrerLevelId: Yup.number(),
        employmentTypeId: Yup.number(),
        salaryRangeId: Yup.number(),
        yearsExp: Yup.string(),
        domaineDactiviteId: Yup.number(),
        // companieId: Yup.number(),
        employerId: Yup.number(),
        countryId: Yup.number(),
        cityId: Yup.number(),
    });

    let formik = useFormik({

        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: values => {
            setDisplay(true);
            axios.put(`${process.env.REACT_APP_API_URL}/annonces/${id}`, {
                ...values,
                carrerLevelId: Number(values?.carrerLevelId),
                employmentTypeId: Number(id),
                salaryRangeId: Number(values?.salaryRangeId),
                yearsExp: values?.yearsExp,
                domaineDactiviteId: Number(values?.domaineDactiviteId),
                // companieId: Number(companieId),
                employerId: Number(values?.employerId),
                countryId: Number(values?.countryId),
                cityId: Number(values?.cityId),

            }).then((res) => {
                setDisplay(false)
                navigate(`/dashboard`)
            }).catch((err) => { console.log(err) });
        },
    });

    useEffect((
    ) => {

        console.log(offer)
    }, [offer])



    return (

        <div style={{
            justifySelf: "center",
            padding: "0px 200px",
            // backgroundColor: "#a618160f",
            backgroundColor: "#fdc8000f",
        }} >
            <div
                style={{
                    justifySelf: "center",
                    padding: "100px 0px",
                    // width: "80%"
                }}
            >
                <div className="pxp-dashboard-content-details">
                    <h1>Nouvelle offre d'emploi</h1>
                    <p className="pxp-text-light">Ajoutez un nouveau poste à la liste des postes de votre entreprise.</p>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row mt-4 mt-lg-5">
                            <div className="col-xxl-8">
                                <div className="row">
                                    <Input
                                        formik={formik}
                                        title={"Titre d'emploi"}
                                        id={"name"} />
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <Select
                                            formik={formik}
                                            title={"Ville"}
                                            id={"cityId"}
                                            list={city}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            formik={formik}
                                            title={"Pays"}
                                            id={"countryId"}
                                            list={country}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <Select
                                        formik={formik}
                                        title="Domaine d'activité"
                                        id="domaineDactiviteId"
                                        list={domaines} />
                                </div>
                                <div className="row">
                                    <Textarea
                                        formik={formik}
                                        title={"Description de l'emploi"}
                                        id={"discription"} />
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <Select
                                            formik={formik}
                                            title={"Expérience"}
                                            id={"yearsExp"}
                                            list={years}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            formik={formik}
                                            title={"Niveau de carrière"}
                                            id={"carrerLevelId"}
                                            list={carrerLevel} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <Select
                                            formik={formik}
                                            title={"Type d'emploi"}
                                            id={"employmentTypeId"}
                                            list={employmentType} />
                                    </div>
                                    <div className="col-sm-6">
                                        <Select
                                            formik={formik}
                                            title={"Échelle salariale"}
                                            id={"salaryRangeId"}
                                            list={salaryRange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SubmitButton
                            display={display} />
                    </form>
                </div>
            </div >
        </div >
    );
}

export default UpdateAnnonce;
