import React from "react";
import ImmigrationIcon from "../assets/images/Immigration_icon.png";
import RecrutementIcon from "../assets/images/recrutement_icon.png";
import IntegrationIcon from "../assets/images/Integration_icon.png";

export default function EmployerDetails() {
	return (
		<section className="pt-100 pb-100" style={{ backgroundColor: "#fff" }}>
			<div className="pxp-container">
				<div className="row">
					{/* <h2 className="pxp-section-h2 text-center">
						Comment ça marche
					</h2> */}
					{/* <div className="col-lg-12"> */}
					<div className="d-flex flex-column flex-lg-row justify-content-around how-to-sec pxp-animate-in-top pxp-in mt-4 mt-md-5 pxp-animate-in">
						<div className="how-to col d-flex flex-column align-items-center">
							<div
								style={{
									height: "300px",
									width: "300px",
									borderRadius: "50%",
									backgroundColor: "transparent",
									border: "1px dashed",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									marginBottom: "20px",
								}}
							>
								<div
									style={{
										backgroundColor: "#fff",
										height: "250px",
										width: "250px",
										borderRadius: "50%",
										color: "#000",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "28px",
										fontWeight: "700",
										boxShadow: "rgba(129, 207, 241, 0.26) 0px 10px 20px",

									}}
								>
									<img
										style={{ height: "100%" }}
										src={RecrutementIcon}
										alt="RecrutementIcon"
									/>
								</div>
							</div>
							<div
								style={{
									fontSize: "28px",
									fontWeight: "700",
									color: "#000",
									marginBottom: "10px",
									textAlign: "left",
								}}
							>
								Recrutement
							</div>
							<div
								style={{
									width: "60%",
									display: "flex",
									alignItems: "flex-start",
									flexDirection: "column",
								}}
							>
								<div style={{ textAlign: "left" }}>
									• Analyse de vos besoins
								</div>
								<div style={{ textAlign: "left" }}>
									• Présélection des profils
								</div>
								<div style={{ textAlign: "left" }}>
									• Entretien d'embauche
								</div>
							</div>
						</div>
						<div className="how-to col d-flex flex-column align-items-center">
							<div
								style={{
									height: "300px",
									width: "300px",
									borderRadius: "50%",
									backgroundColor: "transparent",
									border: "1px dashed",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									marginBottom: "20px",
								}}
							>
								<div
									style={{
										backgroundColor: "#fff",
										height: "250px",
										width: "250px",
										borderRadius: "50%",
										color: "#000",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "28px",
										fontWeight: "700",
										boxShadow: "rgba(129, 207, 241, 0.26) 0px 10px 20px",
									}}
								>
									<img
										style={{ height: "100%" }}
										src={ImmigrationIcon}
										alt="ImmigrationIcon"
									/>
								</div>
							</div>
							<div
								style={{
									fontSize: "28px",
									fontWeight: "700",
									color: "#000",
									marginBottom: "10px",
									textAlign: "left",
								}}
							>
								Immigration
							</div>
							<div
								style={{
									width: "60%",
									display: "flex",
									alignItems: "flex-start",
									flexDirection: "column",
								}}
							>
								<div style={{ textAlign: "left" }}>
									• Accompagnement
								</div>
								<div style={{ textAlign: "left" }}>
									• Suivi du dossier
								</div>
								<div style={{ textAlign: "left" }}>
									• Service à la pointe de vos attentes
								</div>
							</div>
						</div>
						<div className="how-to col d-flex flex-column align-items-center">
							<div
								style={{
									height: "300px",
									width: "300px",
									borderRadius: "50%",
									backgroundColor: "transparent",
									border: "1px dashed",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									marginBottom: "20px",
								}}
							>
								<div
									style={{
										backgroundColor: "#fff",
										height: "250px",
										width: "250px",
										borderRadius: "50%",
										color: "#000",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "28px",
										fontWeight: "700",
										boxShadow: "rgba(129, 207, 241, 0.26) 0px 10px 20px",
									}}
								>
									<img
										style={{ height: "100%" }}
										src={IntegrationIcon}
										alt="IntegrationIcon"
									/>
								</div>
							</div>
							<div
								style={{
									fontSize: "28px",
									fontWeight: "700",
									color: "#000",
									marginBottom: "10px",
									textAlign: "left",
								}}
							>
								Intégration
							</div>
							<div
								style={{
									width: "60%",
									display: "flex",
									alignItems: "flex-start",
									flexDirection: "column",
								}}
							>
								<div style={{ textAlign: "left" }}>
									• Accueil à l'aéroport
								</div>
								<div style={{ textAlign: "left" }}>
									• Installation
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
