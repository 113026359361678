import React, { useEffect, useState } from "react";
import { Input, Select } from "../../components/inputDash/index";
import { instance } from "../../lib/axios";
import { LEVELS, GENDER, LEVELS_LANG } from "./statiqueList";
import { InputView } from "./viewerPdf";
import axios from "axios";
import SubmitButton from "../../components/submitButton/index";
import { Eggy } from '@s-r0/eggy-js';
import { useFormik } from "formik";
import * as Yup from "yup";

const Candidateprofile = ({ data }) => {
	const { profile, id } = data;
	const [diplomeList, setdiplomeList] = useState([]);
	const [domainDactivityList, setdomainDactivityList] = useState([]);
	const [display, setDisplay] = useState(false);
	const [file, setFile] = useState(profile?.cvPath || null);

	useEffect(() => {
		instance
			.get("/domaines")
			.then((res) => {
				setdomainDactivityList(res?.data?.results);
			})
			.catch((err) => {
				console.log(err);
			});
		instance
			.get("/diplomes")
			.then((res) => {
				setdiplomeList(res?.data?.results);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const validationSchema = Yup.object({
		firstName: Yup.string().min(3, "Must be greater then 3 characters"),
		lastName: Yup.string().min(3, "Must be greater then 3 characters"),
		email: Yup.string(),
		phone: Yup.string(),
		gender: Yup.string().oneOf(["man", "woman"], "gender doesn't exist"),
		address: Yup.string(),
		diplomasName: Yup.string(),
		yearsExp: Yup.string(),
		languageLevel: Yup.string(),
		diplomeId: Yup.number(),
		domaineDactiviteId: Yup.number(),
	});

	const formik = useFormik({
		initialValues: {
			firstName: profile?.firstName,
			lastName: profile?.lastName,
			email: profile?.email,
			phone: profile?.phone,
			gender: profile?.gender,
			address: profile?.address,
			diplomasName: profile?.diplomasName,
			yearsExp: profile?.yearsExp,
			languageLevel: profile?.languageLevel,
			diplomeId: profile?.diplomeId,
			domaineDactiviteId: profile?.domaineDactiviteId,
		},
		validationSchema,
		onSubmit: (values) => {
			setDisplay(true);
			axios
				.put(`${process.env.REACT_APP_API_URL}/candidates/${id}`, {
					...values,
					diplomeId: Number(values?.diplomeId),
					domaineDactiviteId: Number(values?.domaineDactiviteId),
				})
			.then(async(res) => {
                await Eggy({
                    title: "Success",
                    message: "profile updated !",
                    type: 'success',
                });
					setDisplay(false);
				})
				.catch(async(err) => {
					await Eggy({
						title: "Error",
						message: "profile doesn't updated !",
						type: 'error',
					});
					console.log(err);
				});
			if (file) {
				const formData = new FormData();
				formData.append("cv_file", file);
				axios
					.put(
						`${process.env.REACT_APP_API_URL}/candidates/updateCv/${id}`,
						formData
					)
				.then(async(res) => {
					await Eggy({
						title: "Success",
						message: "cv added !",
						type: 'success',
					});
						setDisplay(false);
					})
					.catch(async(err) => {
						await Eggy({
							title: "Error",
							message: "cv doesn't added !",
							type: 'error',
						});
						console.log(err);
					});
			}
		},
	});
	return (
		<div
			style={{
				justifySelf: "center",
				padding: "100px 0px",
				width: "80%",
			}}
		>
			<div className="pxp-dashboard-content-details">
				<h1>Editer le profil</h1>
				<p className="pxp-text-light">
					Modifiez les informations de votre page de profil de
					candidat.
				</p>
				<form onSubmit={formik.handleSubmit}>
					<div className="row mt-4 mt-lg-5">
						<div className="col-xxl-8">
							<div className="row">
								<div className="col-sm-6">
									<Input
										formik={formik}
										title={"Nom"}
										id={"firstName"}
									/>
								</div>
								<div className="col-sm-6">
									<Input
										formik={formik}
										title={"Prenom"}
										id={"lastName"}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6">
									<Input
										formik={formik}
										title={"Email"}
										id={"email"}
									/>
								</div>
								<div className="col-sm-6">
									<Input
										formik={formik}
										title={"Phone"}
										id={"phone"}
									/>
								</div>
							</div>
							<Select
								formik={formik}
								title="Genre"
								id="gender"
								list={GENDER}
							/>

							<Input
								formik={formik}
								title={"Adresse"}
								id={"address"}
							/>
							<Input
								formik={formik}
								title={"Nom de Diplôme"}
								id={"diplomasName"}
							/>
							<div className="row">
								<div className="col-sm-6">
									<Select
										formik={formik}
										title="Années D'expérience"
										id="yearsExp"
										list={LEVELS}
									/>
								</div>
								<div className="col-sm-6">
									<Select
										formik={formik}
										title="Niveau de Langue"
										id="languageLevel"
										list={LEVELS_LANG}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6">
									<Select
										formik={formik}
										title="Diplome"
										id="diplomeId"
										list={diplomeList}
									/>
								</div>
								<div className="col-sm-6">
									<Select
										formik={formik}
										title="Domaine Dactivite"
										id="domaineDactiviteId"
										list={domainDactivityList}
									/>
								</div>
							</div>
						</div>
					</div>
					<InputView setFile={setFile} id="cv_file" />
					<SubmitButton display={display} />
				</form>
			</div>
		</div>
	);
};

export default Candidateprofile;
