import React, { useState, createContext, useEffect, useContext } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import axios from "axios";
import jwt_decode from 'jwt-decode';
export const AuthContext = createContext();

function AuthProvider(props) {
	const initial = { type: null, id: null };
	const [auth, setAuth] = useState(initial);

	useEffect(() => {
		const token = localStorage.getItem("token");
		if (token) {
			(async () => {
				const {
					data: { success },
				} = await axios.get(`${process.env.REACT_APP_API_URL}/auth/token`, {
					headers: { Authorization: `Bearer ${token}` },
				});

				if (success) {
					const decoded = jwt_decode(token);
					setAuth((init) => ({
						// ...init,
						token,
						id: decoded?.id,
						type: decoded?.type,
					}));
				} else {
					setAuth((oldValue) => {
						return { ...oldValue, token: "" };
					});
					localStorage.clear();
				}
			})();
		} else {
			setAuth((oldValue) => {
				return { ...oldValue, token: "" };
			});
		}
	}, []);
	return (
		<AuthContext.Provider value={{ auth, setAuth }}>
			{props.children}
		</AuthContext.Provider>
	);
}

/**
 * in general cases I dont need to check the local storage
 */
export const RequireAuth = () => {
	const { auth } = useContext(AuthContext);

	const location = useLocation();

	const [loading, setloading] = useState(true);

	useEffect(() => {
		if (auth?.token !== "") setloading(false);
	}, [loading, auth]);

	// if (loading) return null;
	// console.log(auth)
	return auth ? (
		<Outlet />
	) : (
		<Navigate to="/" state={{ from: location }} replace />
	);
};

export default AuthProvider;
