/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/Auth";
import EmployerDetails from "./empoyerFile";
import CondidateDetails from "./candidatefile";

const Home = () => {
	const { auth } = useContext(AuthContext);

	//need make login work
	useEffect(() => {
		console.log(auth)
	}, [auth])
	return (
		<div
			style={{
				// backgroundColor: "#a618160f",
                backgroundColor: "#e6f0f9",
				display: "flex", flexDirection: "row", height: "100%"
			}}
		>
			{((auth?.type === 'c')
				? <CondidateDetails id={auth?.id} /> : ((auth?.type === 'e')
					? <EmployerDetails id={auth?.id} /> : <>loading...</>))}
		</div>
	);
}

export default Home;
