/* eslint-disable no-unreachable */
import React, { useState, useContext } from "react";
import { instance } from "../../../lib/axios";
import { AuthContext } from "../../../context/Auth";
import { useNavigate } from "react-router-dom";

const RowTable = ({ Row, Key }) => {
    const { auth } = useContext(AuthContext);
    const navigate = useNavigate();
    console.log("this is row", auth)
    const [conversationRoom, setConversationRoom] = useState({
        rommName: `${Row?.id}-${auth?.id}`,
        candidateId: Row?.id,
        employerId: auth?.id, 	
        candidate: `${Row?.firstName} ${Row?.lastName}`,
        employer: "employer",
    });

    const HandelSendMail = async () => {
        await instance.post("/conversations", conversationRoom)
            .then((res) => {

                setTimeout(() => {
                    navigate("/dashboard", { state: 20}, { replace: true });
                }, 850);
            })
            .catch((err) => { console.log(err) })
    }

    return (
        <tr key={`0RowTable${Key}${Row?.id}`} >
            {/* <td><input type="checkbox" className="form-check-input" /></td> */}
            <td key={`1RowTable${Key}${Row?.id}`}>
                <a href={Row?.offerLink}>
                    <div className="pxp-candidate-dashboard-job-title">{Row?.lastName}{" "} {Row?.firstName}</div>
                    {/* <div className="pxp-candidate-dashboard-job-location">
                        <span className="fa fa-globe me-1"></span>{Row?.country}, {Row?.city}</div> */}
                </a>
            </td>
            <td key={`3RowTable${Key}${Row?.id}`}><div className="pxp-candidate-dashboard-job-category">{Row?.email}</div></td>
            <td key={`4RowTable${Key}${Row?.id}`}><div className="pxp-candidate-dashboard-job-type">{Row?.phone}</div></td>
            <td key={`5RowTable${Key}${Row?.id}`}><div className="pxp-candidate-dashboard-job-date mt-1">{Row?.diplome?.name || "-"}</div></td>
            <td key={`5RowTable${Key}${Row?.id}`}><div className="pxp-candidate-dashboard-job-date mt-1">{Row?.domaineDactiviteId?.name || "-"}</div></td>
            <td>
                <div className="pxp-dashboard-table-options">
                    <ul className="list-unstyled">
                        <li><button title="sendMail"><span className="fa fa-envelope" onClick={HandelSendMail}></span></button></li>
                    </ul>
                </div>
            </td>
        </tr>

    )
}

export default RowTable;