/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import dayjs from "dayjs";
import { AuthContext } from "../../context/Auth";

const MessageCard = ({ data, isActive, index, setMessageIndex }) => {
	const status = isActive ? "pxp-active" : "";
	const { auth } = useContext(AuthContext);

	const name =
		data?.candidateId === auth?.id ? data?.candidate : data?.employer;

	return (
		<li className={status}>
			<a
				href="#"
				className="pxp-dashboard-inbox-list-item"
				onClick={() => {
					setMessageIndex(index);
				}}
			>
				<div className="pxp-dashboard-inbox-list-item-top">
					<div className="pxp-dashboard-inbox-list-item-left">
						{/* <div className="pxp-dashboard-inbox-list-item-avatar pxp-cover" style={{ background: "grey" }}></div> */}
						<div className="pxp-dashboard-inbox-list-item-name ms-2">
							{name}
						</div>
					</div>
					<div className="pxp-dashboard-inbox-list-item-right">
						<div className="pxp-dashboard-inbox-list-item-time">
							{data?.messages?.length > 0 ? dayjs(data?.messages?.[data?.messages?.length - 1]?.createdAt).format(
								"DD-MM-YYYY HH:mm"
							) :  dayjs(data?.messages?.createdAt).format(
								"DD-MM-YYYY HH:mm")}
						</div>
					</div>
				</div>
				<div className="mt-2 d-flex justify-content-between">
					<div className="pxp-dashboard-inbox-list-item-text pxp-text-light">
						{data?.messages?.length > 0 ? data?.messages?.[data?.messages?.length - 1]?.text : "send message"}
					</div>
					{/* <div className="pxp-dashboard-inbox-list-item-no ms-3"><span className="badge rounded-pill">3</span></div> */}
				</div>
			</a>
		</li>
	);
};

export default MessageCard;
