/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import Content from "../../components/layout/Dashboard/Content";
import SidePanel from "../../components/layout/Dashboard/SidePanel";
import CandidatePtofile from "../../pages/Dashboard/Candidateprofile";
import Inbox from "../../pages/inbox";
import CandidateAnnoncesList from "../../pages/Dashboard/CandidateAnnoncesList";
import Password from "../../pages/Dashboard/password";
import NavBar from "../../components/layout/Dashboard/navBar";
import { instance } from "../../lib/axios";
import { AuthContext } from "../../context/Auth";

const CondidateDetails = ({ id }) => {
	const { auth } = useContext(AuthContext);
	const user = auth?.user;
	const [position, setPosition] = useState(1);
	const [profile, setProfile] = useState(user);
	const [password, setPassword] = useState("");
	//need make login work
	const candidateId = id;
	useEffect(() => {
		instance
			.get(`/candidates/${candidateId}`)
			.then((res) => {
				const data = res?.data?.results;
				setProfile({
					firstName: data?.firstName,
					lastName: data?.lastName,
					email: data?.email,
					phone: data?.phone,
					gender: data?.gender,
					address: data?.address,
					diplomasName: data?.diplomasName,
					yearsExp: data?.yearsExp,
					languageLevel: data?.languageLevel,
					diplomeId: data?.diplomeId,
					domaineDactiviteId: data?.domaineDactiviteId,
				});
				setPassword(data?.password);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const panelCandidate = [
		{ spanClass: "fa fa-home", title: "Dashboard", status: 1 },
		{ spanClass: "fa fa-pencil", title: "Edit Profile", status: 1 },
		{ spanClass: "fa fa-lock", title: "Change Password", status: 1 },
		{ spanClass: "fa fa-home", title: "Annonces", status: 1 },
	];

	useEffect(() => {}, [profile, password]);

	return (
		<div
			style={{
				// backgroundColor: "#a618160f",
				backgroundColor: "#e6f0f9",
				display: "flex",
				flexDirection: "row",
				height: "100%",
			}}
		>
			<SidePanel
				position={position}
				setPosition={setPosition}
				panel={panelCandidate}
			/>
			<NavBar />
			{position === 0 ? (
				<Content />
			) : position === 1 ? (
				<CandidatePtofile data={{ profile, id: candidateId }} />
			) : position === 2 ? (
				<Password
					data={{ password, id: candidateId, user: "candidates" }}
				/>
			) : position === 3 ? (
				<CandidateAnnoncesList data={{ profile, id: candidateId }} />
			) : position === 20 ? (
				<Inbox />
			) : (
				<>ok</>
			)}
		</div>
	);
};

export default CondidateDetails;
