/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
import { instance } from "../../lib/axios";
import { useParams } from 'react-router-dom';
import relativeTime from 'dayjs/plugin/relativeTime';
import { AuthContext } from "../../context/Auth";
import { Eggy } from '@s-r0/eggy-js';

const Annonce = () => {
    dayjs.extend(relativeTime)

    let { id } = useParams();
    const { auth } = useContext(AuthContext);
    const display = auth?.type === "e" ? "none" : "block";

	const candidateId = auth?.id;
    const [data, setData] = useState({})
    const [companiesId, setCompaniesId] = useState(null);
    useEffect(() => {
        instance.get(`/annonces/view/${id}`).then((res) => {
            const temp = res?.data?.results;
            console.log(temp)
            setCompaniesId(res?.data?.results?.companieId);
            setData({
                carrerLevel: temp?.carrerLevel?.name,
                // employentType: temp?.employentType?.name,
                salaryRange: temp?.salaryRange?.name,
                experience: temp?.yearsExp,
                title: temp?.name,
                company: temp?.companie?.name,
                city: temp?.city?.name,
                country: temp?.Country?.name,
                discription: temp?.discription,
                createdAt: temp?.createdAt,
            })
        }).catch((err) => { console.log(err) });

    }, [])

    useEffect(() => {
        if (companiesId)
            instance.get(`/companies/${companiesId}`).then((res) => {
                const temp = res?.data?.results;
                setData({
                    ...data,
                    companyName: temp?.name,
                    companySize: temp?.companySize,
                    foundedIn: temp?.foundedIn,
                    phone: temp?.phone,
                    email: temp?.email,
                    website: temp?.website,
                    discription: temp?.discription,
                    domaineDactivite: temp?.domaineDactivite?.name,
                    countryCompanie: temp?.Country?.name,
                    cityCompanie: temp?.city?.name,
                })
            }).catch((err) => { console.log(err) });
    }, [companiesId])
    useEffect(() => {
    }, [companiesId, data])


const HandelApply = async () => {
    await instance.post(`/candidate-apply`, {offerId: Number(id), candidateId})
    .then(async(res) => {
        await Eggy({
            title: "Success",
            message: "applied successfully !",
            type: 'success',
        });
        const temp = res?.data?.results;
  
    }).catch(async(err) => {
        await Eggy({
            title: "Error",
            message: "apply doesn't sended !",
            type: 'error',
        }); console.log(err) });
}

    return (
        <>
            <section className="pxp-single-company-hero pxp-cover" style={{ backgroundImage: "url(images/ph-big.jpg);" }}>
                <div className="pxp-hero-opacity"></div>
                <div className="pxp-single-company-hero-caption">
                    <div className="pxp-container">
                        <div className="pxp-single-company-hero-content">
                            <div className="pxp-single-company-hero-logo" style={{ backgroundImage: "url(images/company-logo-1.png);" }}></div>
                            <div className="pxp-single-company-hero-title">
                                <h1>{data?.company}</h1>
                                <div className="pxp-single-company-hero-location"><span className="fa fa-globe"></span>{data?.city} - {data?.country}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="pxp-container">
                    {/* <div className="pxp-single-job-cover pxp-cover" style={{backgroundImage: "url(images/ph-big.jpg)"}}></div>
                <div className="pxp-single-job-cover-logo" style={{backgroundImage: "url(images/company-logo-1.png)"}}></div> */}

                    <div className="pxp-single-job-content mt-4 mt-lg-5">
                        <div className="row">
                            <div className="col-lg-7 col-xl-8 col-xxl-9">
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-xl-8 col-xxl-6">
                                        <h1>{data?.title}</h1>
                                        <div className="pxp-single-job-company-location">
                                            by <a href="single-company-1.html" className="pxp-single-job-company">{data?.company}</a> in{" "}
                                            <a href="jobs-list-1.html" className="pxp-single-job-location">{data?.city}, {data?.contry}</a>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="pxp-single-job-options mt-4 col-xl-0">
                      
                                            <button  style={{display: display}} onClick={async()=> {
                                            await HandelApply()
                                        }} className="btn ms-2 pxp-single-job-apply-btn rounded-pill">Apply Now</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4 justify-content-between align-items-center">
                   
                                    <div className="col-auto">
                                        <div className="pxp-single-job-date pxp-text-light">{dayjs(data?.createdAt).fromNow()}</div>
                                    </div>
                                </div>
                                {/* offer text discription info */}
                                <div className="pxp-single-job-content-details mt-4 mt-lg-5">
                                    <h4>Overview</h4>
                                    <p>{data?.discription}</p>

                                    <div  style={{display: display}} className="mt-4 mt-lg-5">
                                        <a onClick={async()=> {
                                            await HandelApply()
                                        }} href="#" className="btn rounded-pill pxp-section-cta">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-xl-4 col-xxl-3">
                                {/* offer card info */}
                                <div className="pxp-single-job-side-panel mt-5 mt-lg-0">
                                    <div>
                                        <div className="pxp-single-job-side-info-label pxp-text-light">Experience</div>
                                        <div className="pxp-single-job-side-info-data">Minimum {data?.experience} year</div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="pxp-single-job-side-info-label pxp-text-light">Work Level</div>
                                        <div className="pxp-single-job-side-info-data">{data?.carrerLevel}</div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="pxp-single-job-side-info-label pxp-text-light">Employment Type</div>
                                        <div className="pxp-single-job-side-info-data">{data?.employentType}</div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="pxp-single-job-side-info-label pxp-text-light">Salary</div>
                                        <div className="pxp-single-job-side-info-data">{data?.salaryRange} / year</div>
                                    </div>
                                </div>
                                {/* company card info */}
                                <div className="mt-3 mt-lg-4 pxp-single-job-side-panel">
                                    <div className="pxp-single-job-side-company">
                                        <div className="pxp-single-job-side-company-logo pxp-cover" style={{ backgroundImage: "url(images/company-logo-1.png)" }}></div>
                                        <div className="pxp-single-job-side-company-profile">
                                            <div className="pxp-single-job-side-company-name">{data?.companyName}</div>
                                            <a href="single-company-1.html">View profile</a>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="pxp-single-job-side-info-label pxp-text-light">Industry</div>
                                        <div className="pxp-single-job-side-info-data">{data?.domaineDactivite}</div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="pxp-single-job-side-info-label pxp-text-light">Company size</div>
                                        <div className="pxp-single-job-side-info-data">{data?.companySize}</div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="pxp-single-job-side-info-label pxp-text-light">Founded in</div>
                                        <div className="pxp-single-job-side-info-data">{data?.foundedIn}</div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="pxp-single-job-side-info-label pxp-text-light">Phone</div>
                                        <div className="pxp-single-job-side-info-data">{data?.phone}</div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="pxp-single-job-side-info-label pxp-text-light">Email</div>
                                        <div className="pxp-single-job-side-info-data">{data?.email}</div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="pxp-single-job-side-info-label pxp-text-light">Location</div>
                                        <div className="pxp-single-job-side-info-data">{data?.city}, {data?.country}</div>
                                    </div>
                                    <div className="mt-4">
                                        <div className="pxp-single-job-side-info-label pxp-text-light">Website</div>
                                        <div className="pxp-single-job-side-info-data"><a href="#">{data?.website}</a></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> </>
    )
}

export default Annonce;