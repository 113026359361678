import $ from "jquery";
import animateHTML from "./animateHTML";
import animateHeroElement from "./animateHeroElement";
import animateOnMouseMove from "./animateOnMouseMove";

export default function handlePreloader() {
	if ($(".pxp-preloader").length > 0) {
		$(".pxp-preloader")
			.delay(200)
			.fadeOut(500, function () {
				animateHTML().init();
				animateHeroElement(".pxp-hero-right-bg-card");
				animateHeroElement(".pxp-hero-card-info");
				animateHeroElement(".pxp-hero-cards-container");
				animateHeroElement(".pxp-hero-center-carousel");
				setTimeout(function () {
					animateHeroElement(".pxp-hero-stats-item");
				}, 200);
				setTimeout(function () {
					animateHeroElement(".pxp-header-side-image");
				}, 200);

				setTimeout(function () {
					animateHeroElement(".pxp-contact-us-form");
				}, 200);

				animateHeroElement(".pxp-hero-boxed-circulars");
				animateHeroElement(".pxp-hero-boxed-icon-circle-1");
				animateHeroElement(".pxp-hero-boxed-icon-circle-2");

				animateHeroElement(".pxp-hero-boxed-info-card-big");
				animateHeroElement(".pxp-hero-boxed-info-card-small");
				animateHeroElement(".pxp-hero-boxed-info-list-container");

				animateOnMouseMove(".pxp-mouse-move");
			});
	}
}
