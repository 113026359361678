/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { instance } from "../../lib/axios";
import HeaderTable from "../../components/table/headerTable.js";
import { BodyTAble } from "../../components/table/bodyTable.js";
import NextPageList from "../../components/table/nextPage.js";
import { SelectSearch, InputSearch } from "../../components/inputSearch";

import { useFormik } from "formik";
import * as Yup from "yup";

const candidateTitels = [
	{ with: "25%", title: "Travail" },
	{ with: "15%", title: "Compagnie" },
	{ with: "20%", title: "Catégorie" },
	{ with: "12%", title: "Type" },
	{ title: "Date" },
];

const getAnnoncesByFilters = (values, queryParams, setOffers) => {
	console.log("values", values);
	instance
		.get(`/annonces`, {
			params: {
				...queryParams,
				filters: values,
			},
		})
		.then((res) => {
			console.log("filter res", res?.data?.results);
			setOffers(res?.data?.results);
		})
		.then((res) => {})
		.catch((err) => {
			console.log(err);
		});
};

const CandidateAnnoncesList = ({ data }) => {
	const [page, setPage] = useState(0);
	const [rowslenght, setRowslenght] = useState(5);
	const [filter, setFilters] = useState({});
	const queryParams = {
		sort: "desc",
		skip: page,
		take: rowslenght,
		filters: filter,
	};

	const [offers, setOffers] = useState([]);

	useEffect(() => {
		instance
			.get(`/annonces`, { params: queryParams })
			.then((res) => {
				setOffers(res?.data?.results);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [page, rowslenght]);

	const [domainesList, setDomainesList] = useState([]);
	const [typeList, setTypeList] = useState([]);
	const [companiesList, setCompaniesList] = useState([]);

	useEffect(() => {
		instance
			.get("/domaines")
			.then((res) => {
				setDomainesList(res?.data?.results);
			})
			.catch((err) => {
				console.log(err);
			});
		instance
			.get("/employer-types")
			.then((res) => {
				setTypeList(res?.data?.results);
			})
			.catch((err) => {
				console.log(err);
			});
		instance
			.get("/companies")
			.then((res) => {
				setCompaniesList(res?.data?.results);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const validationSchema = Yup.object({
		name: Yup.string(),
		diplomeId: Yup.number(),
		companieId: Yup.number(),
		employmentTypeId: Yup.number(),
		domaineDactiviteId: Yup.number(),
	});

	const fillFilterObject = (values) => {
		let temp = {};
		if (values?.name?.length > 0) {
			temp = { ...temp, name: values?.name };
		}
		if (values?.companieId?.length > 0) {
			temp = { ...temp, companieId: Number(values?.companieId) };
		}
		if (values?.domaineDactiviteId?.length > 0) {
			temp = {
				...temp,
				domaineDactiviteId: Number(values?.domaineDactiviteId),
			};
		}
		if (values?.employmentTypeId?.length > 0) {
			temp = {
				...temp,
				employmentTypeId: Number(values?.employmentTypeId),
			};
		}
		return temp;
	};

	const formik = useFormik({
		initialValues: {
			name: "",
			diplomeId: "",
			companieId: "",
			employmentTypeId: "",
			domaineDactiviteId: "",
		},
		validationSchema,
		onSubmit: (values) => {
			setFilters(fillFilterObject(values));
			getAnnoncesByFilters(filter, queryParams, setOffers);
		},
	});

	useEffect(() => {}, [offers]);
	return (
		<div
			style={{
				justifySelf: "center",
				padding: "100px 0px",
				width: "80%",
			}}
		>
			<div className="pxp-dashboard-content-details">
				<h1>Annonces d'emplois</h1>
				{/* <p className="pxp-text-light">Detailed list of your job applications.</p> */}

				<div className="mt-4 mt-lg-5">
					<div className="row justify-content-between align-content-center">
						<div className="pxp-container">
							<div className="pxp-hero-form pxp-hero-form-round pxp-large mt-3 mt-lg-4">
								<form
									onSubmit={formik.handleSubmit}
									className="row gx-3 align-items-center"
								>
									<InputSearch
										title={"Travail"}
										id={"name"}
										formik={formik}
									/>
									<SelectSearch
										title={"Compagnie"}
										id={"companieId"}
										list={companiesList}
										formik={formik}
									/>
									<SelectSearch
										title={"Catégorie"}
										id={"domaineDactiviteId"}
										list={domainesList}
										formik={formik}
									/>
									<SelectSearch
										title={"Type"}
										id={"employmentTypeId"}
										list={typeList}
										formik={formik}
									/>
									<div className="col-12 col-lg-auto">
										<button type="submit">
											Trouver un emploi
										</button>
									</div>
								</form>
							</div>
						</div>
						<div className="col-auto order-1 order-sm-2">
							<div className="pxp-candidate-dashboard-jobs-search mb-3"></div>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-hover align-middle">
							<HeaderTable titels={candidateTitels} />
							<BodyTAble data={offers} />
						</table>
						<NextPageList
							setPage={setPage}
							page={page}
							rowslenght={rowslenght}
							setRowslenght={setRowslenght}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CandidateAnnoncesList;
