/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import MessageCard from "../../pages/inbox/cardMessage";
import CardInbox from "../../pages/inbox/cardInbox";
import dayjs from "dayjs";
import { instance } from "../../lib/axios";
import { AuthContext } from "../../context/Auth";

const Inbox = () => {
	const { auth } = useContext(AuthContext);
    const [messageIndex, setMessageIndex] = useState(0);
	const [data, setData] = useState([
	]);
	const type =
		auth?.type === "c" ? "candidate" : auth?.type === "e" ? "employer" : "";
	useEffect(() => {
		instance
			.get(`${process.env.REACT_APP_API_URL}/conversations/${type}/${auth?.id}`)
			.then((res) => {
const items = res?.data?.results;

// sort by value
const temp = items.sort((a, b) => {
	const itemA = a?.messages?.[0]?.createdAt ? Date(a?.messages?.[0]?.createdAt) : new Date();
	const itemB = b?.messages?.[0]?.createdAt ? Date(b?.messages?.[0]?.createdAt) : new Date();
	console.log(itemA , itemB);
		return itemA - itemB;
  });
                setData(temp)
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);



	useEffect(() => {}, [messageIndex]);
	return (
		<div
			style={{
				justifySelf: "center",
				padding: "100px 0px",
				width: "80%",
				minWidth: "100vh",
			}}
		>
			<div className="pxp-dashboard-content-details" >
				<h1>Inbox</h1>
				<p className="pxp-text-light">
					Keep in touch with your candidates.
				</p>

				<div className="row mt-4 mt-lg-5" style={{ minWidth: "100vh"}}>
					<div className="col-xxl-4">
						{/* <div className="pxp-dashboard-inbox-search-form">
                            <div className="input-group">
                                <span className="input-group-text"><span className="fa fa-search"></span></span>
                                <input type="text" className="form-control" placeholder="Search messages..."/>
                            </div>
                        </div> */}

            
						<div className="mt-3 mt-lg-4 pxp-dashboard-inbox-list" >
							<ul className="list-unstyled">
								{data?.map((elem, index) => {
									return (
										<MessageCard key={index}
											setMessageIndex={setMessageIndex}
											data={elem}
											isActive={
												index === messageIndex ?? true
											}
											index={index}
										/>
									);
								})}
							</ul>
						</div>
					</div>
					{data?.[messageIndex] && <CardInbox
						user={data?.[messageIndex]?.name}
						messages={data?.[messageIndex]?.messages}
						candidate={data?.[messageIndex]?.candidate} 
						employer={data?.[messageIndex]?.employer}
						canversationInfo={{
							candidateId: data?.[messageIndex]?.candidateId,
							employerId: data?.[messageIndex]?.employerId,
							conversationId: data?.[messageIndex]?.id,
						}} 
					/>}
				</div>
			</div>
		</div>
	);
};

export default Inbox;
