import React, { useState, useEffect } from "react";
import RowTable from "../../components/tableCandidateInOffer/row";

export const BodyTAble = (data) => {

    const [candidates, setCandidates] = useState([]);
    useEffect(() => {
        setCandidates(data?.data);
    }, [data])
    return (
        <tbody >
            {candidates?.length > 0 && candidates?.map((elem, key) => {
                return <RowTable Row={elem?.candidate} Key={`rowtable${key}`} />
            })}
        </tbody>
    );
}
