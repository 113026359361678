/* eslint-disable no-unused-vars */
import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import "./assets/css/font-awesome.min.css";
import "animate.css";

import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Candidat from "./pages/AboutCandidat";

import About from "./pages/About";
import Employer from "./pages/AboutEmployer";
import AddEmployer from "./pages/AddEmployer";
import UnderConstruction from "./pages/UnderConstruction";
import Contact from "./pages/Contact";
import AddCandidate from "./pages/AddCandidate";
import CandidateSignUp from "./pages/CandidateSignUp";
import AddEmployer2Form from "./pages/EmployerForm";
import EmployerFormHome from "./pages/EmployerFormHome";
import CandidateSignIn from "./pages/CandidateSignIn";
import EmployerSignIn from "./pages/EmployerSignIn";
import Home from "./pages/Dashboard/Home";
import Annonce from "./pages/annonce";
import UpdateAnnonce from "./pages/annonce/updateAnnonce.js";
import CandidatesInOffer from "./pages/candidatesInOffer";
// import CandidatePtofile from "./pages/Dashboard/Candidateprofile";
import ConditionUtilisation from "./pages/ConditionUtilisation";
import ConditionDeVentes from "./pages/ConditionDeVentes";
import AuthProvider, { RequireAuth } from "./context/Auth";
import { CompanyPage } from "./pages/companie";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootEl />);

function RootEl() {
	return (
		<>
			<BrowserRouter>
				<AuthProvider>
					<Routes>
						{/* protected Routes */}
						<Route element={<RequireAuth />}>
							<Route path="dashboard" element={<Home />} />
							<Route path="annonce/:id" element={<Annonce />} />
							<Route path="candidatesInOffer/:id" element={<CandidatesInOffer />} />
							<Route path="company/:id" element={<CompanyPage />} />
							<Route path="updateAnnonce/:id" element={<UpdateAnnonce />} />
							{/* <Route path="candidate-profile" element={<CandidatePtofile />} /> */}
						</Route>
						{/* <Route path="dashboard" element={<Home />} /> */}
						<Route path="contact" element={<Contact />} />
						<Route path="candidat" element={<Candidat />} />
						<Route
							path="candidate-sign-up"
							element={<AddCandidate />}
						/>
						<Route path="sign-up" element={<CandidateSignUp />} />
						{/* new */}
						<Route path="login" element={<CandidateSignIn />} />
						<Route
							path="employer-login"
							element={<EmployerSignIn />}
						/>
						{/* end new */}
						<Route
							path="employer-form"
							element={<EmployerFormHome />}
						/>
						<Route
							path="employer-form/:uuid"
							element={<AddEmployer2Form />}
						/>
						<Route
							path="employer-sign-up"
							element={<AddEmployer />}
						/>
						<Route path="employer" element={<Employer />} />
						<Route path="about" element={<About />} />
						<Route path="privacy-policy" element={<About />} />
						<Route path="/" element={<App />} />
						<Route path="Conditions-utilisation" element={<ConditionUtilisation />} />
						<Route path="Conditions-de-ventes" element={<ConditionDeVentes />} />
						<Route path="*" element={<UnderConstruction />} />
					</Routes>
				</AuthProvider>
			</BrowserRouter>
		</>
	);
}

// function AppWithStore() {
// 	return (
// 		<AuthProvider>
// 			<RootEl />
// 		</AuthProvider>
// 	);
// }

// export default AppWithStore;
