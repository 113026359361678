/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";


const HeaderCardInbox = ({ user }) => {
    return (
        <div className="pxp-dashboard-inbox-messages-header">
            <div className="pxp-dashboard-inbox-list-item-left">
                <div className="pxp-dashboard-inbox-list-item-avatar pxp-cover" style={{ backgroundImage: "url(images/ph-small.jpg)" }}></div>
                <div className="pxp-dashboard-inbox-list-item-name ms-2">{user}</div>
            </div>
            {/* <div className="d-flex align-items-center">
            <div className="pxp-dashboard-inbox-list-item-options">
                <button title="Delete conversation"><span className="fa fa-trash-o"></span></button>
            </div>
            <button className="btn rounded-pill pxp-dashboard-inbox-messages-close-btn d-inline-block d-xxl-none"><span className="fa fa-angle-left"></span> Back</button>
        </div> */}
        </div>
    )
}

export default HeaderCardInbox;