export const LEVELS = [
	{
		name: "one",
		id: "one",
	},
	{ name: "two", id: "two" },
	{ name: "three", id: "three" },
	{ name: "four", id: "four" },
	{ name: "five", id: "five" },
	{ name: "six", id: "six" },
	{ name: "seven", id: "seven" },
	{ name: "eight", id: "eight" },
	{ name: "nine", id: "nine" },
	{ name: "ten", id: "ten" },
];

export const GENDER = [
	{ id: "man", name: "Homme" },
	{ id: "woman", name: "Femme" },
];

export const LEVELS_LANG = [
	{
		id: "one",
		name: "Débutant",
	},
	{ id: "four", name: "Moyen" },
	{ id: "seven", name: "Bien" },
	{ id: "ten", name: "Excellent" },
];