/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from "react";
import { instance } from "../../lib/axios";
import { AuthContext } from "../../context/Auth";

const FooterCardInbox = ({ setText, canversationInfo }) => {
	const [value, setValue] = useState("");
	const { auth } = useContext(AuthContext);

	const HandelSendMail = async (msg) => {
		// console.log("msg", msg);
		await instance
			.post(`/conversations/message`, msg)
			.then((res) => {
				const temp = res?.data?.results;
			})
			.catch((err) => {
				console.log(err);
			});
	};
	const handelMessage = async (e) => {
		// console.log(value);
		if (value?.length > 0) {
			setText({
				text: value,
				owner: auth?.id,
				conversationId: canversationInfo?.conversationId,
				candidateId: canversationInfo?.candidateId,
				employerId: canversationInfo?.employerId,
			});
			await HandelSendMail({
				text: value,
				owner: auth?.id,
				conversationId: canversationInfo?.conversationId,
				candidateId: canversationInfo?.candidateId,
				employerId: canversationInfo?.employerId,
			});
			setValue("");
		}
	};

	return (
		<div className="pxp-dashboard-inbox-messages-footer">
			<div className="pxp-dashboard-inbox-messages-footer-field">
				<input
					value={value}
					onChange={(e) => {
						setValue(e?.target?.value);
					}}
					onKeyPress={(e) => {
						console.log(e)
						if (e.key === "Enter") {
							handelMessage(e)
						}
					  }}
					type="text"
					className="form-control"
					placeholder="Type your message here..."
				/>
			</div>
			<div className="pxp-dashboard-inbox-messages-footer-btn">
				<button
					onClick={handelMessage}
					className="btn rounded-pill pxp-section-cta"
				>
					Send
				</button>
			</div>
		</div>
	);
};

export default FooterCardInbox;
