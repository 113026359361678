import React from "react";

export const Input = ({ title, id, type, formik }) => {
    const placeholder = `Ajoutez votre ${title}`;
    return (
        <div className="mb-3">
            <label htmlFor={id} className="form-label">{title}</label>
            <input id={id}
                className="form-control"
                placeholder={placeholder}
                type={type || "text"}
                value={formik?.values[id]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name={id} />
            {formik.touched[id] && formik.errors[id] ? <p className='inputfieldlabel text-green-400'
                style={{ left: '23.03px', top: '100px', color: 'red' }}>{formik.errors[id]}</p> : ''}
        </div>
    );
}

export const Select = ({ title, id, list, formik }) => {
    const temp = `pxp-key-select-${id}`;
    return (
        <div className="mb-3" >
            <label htmlFor={id} className="form-label">{title}</label>
            <div className="input-group">

                <select key={temp} className="form-select"
                    value={formik?.values[id] || "DEFAULT"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name={id}>
                    <option disabled value={"DEFAULT"}>Select {title}</option>
                    {list?.map((elem, index) => { return (<option key={`${id}${index}`} value={elem?.id} >{elem?.name}</option>) })}
                </select>
            </div>
        </div>

    );
}


export const Textarea = ({ title, id, formik }) => {
    const placeholder = `Type the ${title} here...`

    return (
        <div className="mb-3">
            <label
                htmlFor={id}
                className="form-label">
                {title}
            </label>
            <textarea
                className="form-control"
                id={id}
                value={formik?.values[id]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name={id}
                placeholder={placeholder}></textarea>
        </div>
    )
}
