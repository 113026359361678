import React from "react";
// import Globe from "../../assets/images/Carte-Monde-HR-GLOBE.png";
import { COLORS } from "../../utils";

export default function ConditiondeVentes() {
	return (
		<section
			className="pt-100 pb-100"
			style={{ backgroundColor: COLORS.customMainColorLight }}

		>
			<div
				style={{ width: "100%" }}
				className="row justify-content-center"
			>
				<div className="col-xl-7 col-xxl-6">
					{/* <div className="mt-4 mt-md-5 text-center">

						<h3 style={{ color: "#2d2e83" }}>
							Conditions d'utilisation
						</h3>
					</div> */}
					<div className="mt-4 mt-md-5 text-left">
						<h5 style={{ color: "#2d2e83" }}>
							Demande de Souscription
						</h5>
						<p>
							Toute souscription d'un bon de commande auprès de WORLD SELECT implique l'acceptation de
						</p>
					</div>
					<div className="mt-4 mt-md-5 text-left">
						<h5 style={{ color: "#2d2e83" }}>
							Bon de commande et Facturation
						</h5>
						<p>
							L'annonceur désirant pour la première fois passer une commande à WORLD SELECT doit
							adresser tout renseignement requis par ce dernier, notamment destiné à son service
							comptable. Tout bon de commande doit parvenir à WORLD SELECT soit sous format
							électronique, soit par fax.
							L'activation du service ainsi que la facturation interviennent dès la réception du bon de
							commande WORLD SELECT signé et cacheté par le client ou du bon de commande interne du
							client.
							Le Client reçoit alors ses identifiants lui permettant de se connecter à son espace
							professionnel dédié. En cas d'absence de paiement intégral d'une facture venue à
							échéance, après mise en demeure restée sans effet dans un délai de quarante-huit (48)
							heures, la société se réserve le droit de suspendre toute prestation en cours et/ou à venir
							et de désactiver le compte recruteur.
							L'ensemble des éléments permettant au Client de s'identifier et de se connecter aux
							services de la société sont personnels et confidentiels : ils ne peuvent être divulgués à
							des tiers, ni faire l'objet d'une quelconque cession sans l'accord préalable de la société.
							Cette dernière a la faculté de modifier les identifiants du client sous réserve d'en informer
							préalablement celui-ci.
						</p>
					</div>
					<div className="mt-4 mt-md-5 text-left">
						<h5 style={{ color: "#2d2e83" }}>
							Conditions de règlement
						</h5>
						<p>
							Tous nos services sont payables à la réception de la facture. Les clients réguliers pourront
							cependant, après accord préalable de WORLD SELECT, régler leur(s) facture(s) aux conditions
							particulières prédéfinies et accordées par la société.
							Tout retard de paiement entraînera de plein droit :
						</p>
						<ul>
							<li>
								la suspension de l'exécution du contrat,
							</li>
							<li>
								'exigibilité immédiate de toutes les sommes facturées restant dues et des ordres
								non encore facturés,
							</li>
							<li>
								le paiement comptant à la remise de l'ordre de toute nouvelle mise en ligne ou la
								suspension de leur exécution,
							</li>
							<li>
								une facturation d'intérêts de retard calculée sur la base de trois fois le taux
								d'intérêt légal en vigueur figurant sur la facture et réglable à réception par chèque
								bancaire,
							</li>
							<li>
								l'exigibilité d'une indemnité de 15% des sommes dues au titre de clause pénale,
								outre les frais judiciaires éventuels, après l'envoi d'une lettre de mise en demeure
								préalable notifiée par lettre recommandée avec demande d'avis de réception,
								restée sans effet pendant 7 jours.
							</li>
						</ul>
					</div>
					<div className="mt-4 mt-md-5 text-left">
						<h5 style={{ color: "#2d2e83" }}>
							Service client
						</h5>
						<ul>
							<li>
								WORLD SELECT s'engage à fournir un support de qualité conforme aux meilleurs
								standards du marché.
							</li>
						</ul>
						<p>Ses services seront disponibles 24 h /24, 365 jours par an sauf opération de
							maintenance ou d'installation exceptionnelle. Dans ce cas, WORLD SELECT devra
							avertir préalablement le Client dans un délai raisonnable afin de lui permettre de
							s'organiser au mieux.
							En cas d'interruption ou de dysfonctionnement imprévu, WORLD SELECT s'engage à
							rétablir le service dans les meilleurs délais étant précisé qu'elle ne pourra être
							tenue pour responsable des performances des réseaux de télécommunications
							marocains ou étrangers ni du fonctionnement général du réseau Internet dont le
							Client connaît les spécificités, le caractère non commercial du réseau et l'absence
							d'organisation centrale susceptible d'assumer une responsabilité contractuelle.
							Les interruptions de service dues à des anomalies sont traitées en fonction de leur
							niveau de sévérité. La sévérité d'une anomalie traduit l'impact du
							dysfonctionnement sur l'activité du Client. Le niveau de sévérité est déterminé
							lors de la mise en évidence de l'anomalie par le Client.</p>
					</div>
					<div className="mt-4 mt-md-5 text-left">
						<h5 style={{ color: "#2d2e83" }}>
							Taxes
						</h5>
						<ul>
							<li>
								Toute taxes présentes ou futures sont à la charge de l'annonceur et facturées en sus.
							</li>
						</ul>
					</div>
					<div className="mt-4 mt-md-5 text-left">
						<h5 style={{ color: "#2d2e83" }}>
							Confidentialité
						</h5>
						<ul>
							<li>
								WORLD SELECT reconnaît que l'exécution de ses services lui donnera accès à des informations,
								données, fichiers, documents confidentiels du Client. La société s'engage de ce fait à
								considérer comme strictement confidentielles toutes les informations, données, fichiers
								et documents de quelque nature qu'elles soient, relatives au Client, à son système
								informatique et/ou à ses méthodes commerciales, qu'il aura recueillies directement ou
								indirectement à l'occasion de l'exécution du service commandé. Cette obligation s'étend
								à tous les renseignements de quelque nature que ce soit, communiqués par le Client à la
								société et ses préposés dans le déroulement de la mission.
							</li>
							<li>
								Toutefois, WORLD SELECT ne sera pas tenu de conserver un caractère confidentiel aux données
								qui sont, soit accessibles au public, soit déjà connues du public au moment des faits,
								soit élaborées de façon indépendante par la société en dehors du cadre de la commande,
								soit obtenues de tiers par des moyens légitimes. De plus, chaque partie sera en droit de
								divulguer toute information confidentielle à ses assureurs, commissaires aux comptes ou à
								ses avocats, aux autorités administratives ou judiciaires, sur production de la décision
								des autorités concernées ou lorsque la loi l'exige.
							</li>
							<li>
								WORLD SELECT s'engage à prendre toutes les mesures nécessaires à l'égard de son personnel afin
								de faire respecter par ce dernier le secret et la confidentialité de toutes les informations
								recueillies au titre des commandes reçues.
							</li>
						</ul>
					</div>
					<div className="mt-4 mt-md-5 text-left">
						<h5 style={{ color: "#2d2e83" }}>
							Propriété Intellectuelle
						</h5>
						<ul>
							<li>
									Toute reproduction ou imitation non autorisée de ces marques, logos et signes distinctifs
									constitue une contrefaçon passible de poursuites civiles et pénales. Il en est de même pour
									les signes distinctifs des recruteurs ou de tout partenaire. La société WORLD SELECT est propriétaire 
									du site, tant dans ses composntes techniques que graphiques, textuelles ou autres, sous la seule 
									réserve des contenus fournis par les utilisateurs eux-mêmes. Sous les réserves ci-dessus, la société
									est donc seule titulaire de l'nsemble des droits de propriété intellectuelle afférents au site, à 
									son contenu ainsi qu'aux logiciels et bases de données assurant son fonctionnement.
									Le client s'engage à respecter les dits droits de propriété intellectuelle de la société sur le site.
							</li>
							<li>
									En application du Code de la propriété intellectuelle, il est fait interdiction à tout client :
									D'extraire par transfert permanent ou temporaire la totalité ou une partie qualitativement ou
									quantitativement substantielle du contenu de la CVthèque ou/et des Offres accessibles sur le site
									sur un autre support, par tout moyen et sous toute forme que ce soit, de réutiliser la totalité ou
									une partie qualitativement ou quantitativement substantielle du contenu de la CVthèque ou/et des Offres
									par la mise à disposition du public, d'extraire ou de réutiliser à répétition ou de manière systématique
									des parties qualitativement ou quantitativement non substantielles du contenu de la CVthèque ou/et des Offres
									lorsque ces opérations excèdent manifestement les conditions d'utilisation normales du Site, d'exploiter
									commercialement auprès de tiers les droits d'accès ou de publications
									d'Offres, les Offres elles-mêmes.
							</li>
							<li>
									WORLD SELECT se réserve la possibilité de résilier de plein droit toute commande ou contrat et d'engager toute
								action pour faire sanctionner toute atteinte à ses droits de propriété intellectuelle. Le client ne dispose 
								sur le site t sur le contenu fourni par la société que d'une licence d'utilisation de son contenu à titre
									personnel et privé, à l'exclusion de toute visualisation ou diffusion publique. Leclient ne dispose
								d'une autorisation de reproduction que sous la seule forme numérique via son navigateur internet sur son 
								ordinateur de consultation et aux seules fins de visualisation des pages consultées. L'impression papier 
								st atorisée aux fins de copie privée à l'usage exclusif du copiste. Le client est autorisé à créer un 
								lien hypertexte vers les différentes 
								ges du site mais uniquement sans frame.
							</li>
						</ul>
					</div>
					<div className="mt-4 mt-md-5 text-left">
						<h5 style={{ color: "#2d2e83" }}>
						Protection des données personnelles
						</h5>
						<ul>
							<li>
							WORLD SELECT et ses clients s'engagent à respecter les dispositions de la loi 09-08 et 
							du règlement général sur la protection des données (RGPD). Le traitement des données 
							personnelles réalisé à partir du site de la société a fait l'objet d'une déclaration 
							auprès de la CNDP. A ce titre, chaque client ayant accès à ces données s'engage à agir 
							conformément aux dispositions de dites lois, à en prendre connaissance et à 
							respecter toutes les obligations mises à sa charge en tant que client de WORLD SELECT.
							</li>
							<li>
							Pour ce faire, le client s'engage à traiter, tel que cette notion de traitement est 
							définie au sein de la loi précitée, lesdites données uniquement aux fins d'exécution 
							du ou des services achetés et à l'exclusion de toute autre finalité et n'agir que sur 
							instruction de WORLD SELECT, en sa qualité de responsable de traitement. Par ailleurs, le 
							client s'engage à ne jamais divulguer ou transférer les données précitées à des tiers basés 
							au Maroc ou à l'étranger sans le consentement préalable de WORLD SELECT.
							</li>
							<li>
							Aussi, le client s'engage à mettre en œuvre de mesures techniques et organisationnelles 
							appropriées pour protéger les données à caractère personnel contre la destruction accidentelle 
							ou illicite, la perte, l'altération, la diffusion ou l'accès non autorisé et adopter les mesures
							 de sécurité physiques (sécurité des locaux), logiques (sécurité des systèmes d'information) et 
							adaptées à la nature des données et aux risques. 
							</li>
						</ul>
					</div>
					<div className="mt-4 mt-md-5 text-left">
						<h5 style={{ color: "#2d2e83" }}>
						Principes fondamentaux
						</h5>
						<ul>
							<li>
							Les offres d'emploi mises en ligne par les clients devront être en conformité avec la législation en vigueur.
							 WORLD SELECT refusera tout service contraire aux règles et usages de la profession et à la législation. Le Client 
							 s'engage à utiliser de manière raisonnable et raisonnée les services auxquels il a accès, en particulier dans 
							 l'utilisation des bases de données de CV mises à sa disposition. WORLD SELECT se réserve le droit de refuser purement 
							 et simplement tout service, même en cours d'exécution, quand par sa nature, son texte ou sa présentation, il 
							 paraîtrait contraire à l'esprit du site ou susceptible de provoquer des protestations de la part de ses visiteurs 
							 ou de tiers, et plus généralement tout service contraire à ses intérêts financiers ou moraux.
							</li>
							<li>
							WORLD SELECT ne peut être tenu responsable des problèmes de connexion ou d'indisponibilité du réseau, ni des vitesses
							 d'accès ou de l'éventuel non fonctionnement du réseau internet. Les produits et/ou services proposés s'entendent 
							 toujours sous réserve de disponibilité
							 au moment de l'acceptation du bon de commande par WORLD SELECT.
							</li>
							<li>
							WORLD SELECT peut être amené à modifier ou supprimer certains services, en fonction des circonstances, WORLD SELECT se 
							réserve la faculté ou bien de résilier le contrat, ou bien de le laisser suivre son cours. Quelle que soit l'option 
							retenue, le coût de 
							la campagne sera exigible pour le temps effectivement couru.
							</li>
							<li>
							Par ailleurs, les prestations vendues par WORLD SELECT ne doivent être utilisées que par le signataire du contrat, 
							et en aucun cas revendues à des tiers, sans le consentement préalable de WORLD SELECT. Les identifiants et mot de 
							passe permettant la connexion aux services proposés 
							par WORLD SELECT ne sont en aucun cas transférables à des tiers.
							</li>
						</ul>
					</div>
					<div className="mt-4 mt-md-5 text-left">
						<h5 style={{ color: "#2d2e83" }}>
						Règlement des litiges.
						</h5>
						<ul>
							<li>
							Aucune réclamation concernant un retard dans la réalisation d'un service ne sera admise s'il n'a pas été mentionné sur
							 le bon de commande une date limite de réalisation de service.
							</li>
							<li>
							En cas de litige ou d'attente d'avoir, le Client s'engage à payer sans aucun retard la partie non contestée de la facture. 
							En cas de contestation, le Tribunal de Commerce de Casablanca est seul compétent.
							</li>
						</ul>
					</div>
					<div className="mt-4 mt-md-5 text-left">
						<h5 style={{ color: "#2d2e83" }}>
						Loi Applicable, Juridiction.
						</h5>
						<ul>
							<li>
							Les présentes conditions sont soumises au droit marocain et tout litige entre les parties aux présentes sera soumis
							 à la compétence exclusive du Tribunal de Commerce de Casablanca, nonobstant pluralité de défendeurs ou appel en garantie.
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
)}
