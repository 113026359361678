import React from "react";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import ConditionsDescription from "../components/conditions/ConditionsDescription";
// import Logo from "../assets/images/Logo-HR-Globe-Profil.png";
import Logo from "../assets/images/logo-transparant.png";

import AboutHero from "../components/about/AboutHero";
import Background from "../assets/images/Condition-de-utilisation.jpg";

export default function ConditionUtilisation() {
	return (
		<>
			<Header
				logo={Logo}
				header="pxp-header fixed-top pxp-no-bg"
				nav="pxp-nav dropdown-hover-all pxp-light d-none d-xl-block"
				extraNav="pxp-user-nav d-none d-sm-flex"
			/>
						<AboutHero
				bg={Background}
				content={() => (
					<div className="text-center">
						<h1
							style={{
								fontWeight: 50,
								fontSize: "48px",
							}}
						>
							<b
								style={{
									fontWeight: "bold",
									fontSize: "48px",
								}}
							>
								Conditions d'utilisation{" "}
							</b>
						</h1>
					</div>
				)}
			/>
			<ConditionsDescription />
			<Footer />
		</>
	);
}
