import React, { useState } from "react";

export const InputView = ({setFile, id}) => {

    const [url, setUrl] = useState('');

    // Handle the `onChange` event of the `file` input
    const onChange = (e) => {
        const files = e.target.files;
        setFile(e.target.files[0]);
        files.length > 0 && setUrl(URL.createObjectURL(files[0]));
    };
    return (
        <div>
            <div >
                {url ? (
                    <div className="mt-4 mt-lg-5">
                        Upload Your CV
                    </div>
                ) : (
                    <div className="col-xxl-4">
                        <div className="form-label">&nbsp;</div>
                        <div className="pxp-candidate-cover mb-3">
                            <input
                                name={id}
                                type="file" id="pxp-candidate-cover-choose-file" accept="application/pdf" onChange={onChange} />
                            <label htmlFor="pxp-candidate-cover-choose-file" className="pxp-cover"><span>Upload Your CV</span></label>
                        </div>
                    </div>

                )}
            </div>
        </div>
    );
}