import React from "react";

export default function AboutHero({ bg, content }) {
	return (
		<section
			className="pxp-page-image-hero pxp-cover"
			style={{ backgroundImage: `url(${bg})` }}
		>
			<div className="pxp-hero-opacity"></div>
			<div className="pxp-page-image-hero-caption">
				<div className="pxp-container">
					<div className="row justify-content-center">
						<div className="col-9 col-md-8 col-xl-7">
							{React.createElement(content)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
