/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { AuthContext } from "../../context/Auth";

const BodyCardInbox = ({ messages, candidate, employer, text }) => {
	const { auth } = useContext(AuthContext);
	const scroll = useRef();

	let element = messages;
	useEffect(() => {

		if (text) element?.push(text);
		scroll.current.scrollTop = scroll.current.scrollHeight;
	}, [text]);
	useEffect(() => {
	}, [element]);
	return (
		<div ref={scroll}
			className="pxp-dashboard-inbox-messages-content"
		>
			{element?.map((elem, index) => {
				const temp =
					elem?.owner === auth?.id ? (
						<div
							key={index}
							className="pxp-dashboard-inbox-messages-item mt-4"
						>
							<div className="row justify-content-end">
								<div className="col-7">
									<div className="pxp-dashboard-inbox-messages-item-header flex-row-reverse">
										{/* <div
											className="pxp-dashboard-inbox-messages-item-avatar pxp-cover"
											style={{
												backgroundImage:
													"url(images/company-logo-1.png)",
											}}
										></div> */}
										<div className="pxp-dashboard-inbox-messages-item-name me-2">
											{candidate}
										</div>
										<div className="pxp-dashboard-inbox-messages-item-time pxp-text-light me-3">
											{dayjs(elem?.createdAt).format(
												"DD-MM-YYYY HH:mm"
											)}
										</div>
									</div>
									<div className="pxp-dashboard-inbox-messages-item-message pxp-is-self mt-2">
										{elem?.text}
									</div>
								</div>
							</div>
						</div>
					) : (
						<div
							key={index}
							className="pxp-dashboard-inbox-messages-item"
						>
							<div className="row">
								<div className="col-7">
									<div className="pxp-dashboard-inbox-messages-item-header">
										{/* <div
											className="pxp-dashboard-inbox-messages-item-avatar pxp-cover"
											style={{
												backgroundImage:
													"url(images/ph-small.jpg)",
											}}
										></div> */}
										<div className="pxp-dashboard-inbox-messages-item-name ms-2">
											{employer}
										</div>
										<div className="pxp-dashboard-inbox-messages-item-time pxp-text-light ms-3">
											{dayjs(elem?.createdAt).format(
												"DD-MM-YYYY HH:mm"
											)}
										</div>
									</div>
									<div className="pxp-dashboard-inbox-messages-item-message pxp-is-other mt-2">
										{elem?.text}
									</div>
								</div>
							</div>
						</div>
					);
				return temp;
			})}
			<div
				key={"thelast"}
				className="pxp-dashboard-inbox-messages-item mt-4"
			>
				{text?.length > 0 && <div className="row justify-content-end">
					<div className="col-7">
						<div className="pxp-dashboard-inbox-messages-item-header flex-row-reverse">
							{/* <div
											className="pxp-dashboard-inbox-messages-item-avatar pxp-cover"
											style={{
												backgroundImage:
													"url(images/company-logo-1.png)",
											}}
										></div> */}
							<div className="pxp-dashboard-inbox-messages-item-name me-2">
								{candidate}
							</div>
							<div className="pxp-dashboard-inbox-messages-item-time pxp-text-light me-3">
								{dayjs(text?.createAt).format(
									"DD-MM-YYYY HH:mm"
								)}
							</div>
						</div>
						<div className="pxp-dashboard-inbox-messages-item-message pxp-is-self mt-2">
							{text?.text}
						</div>
					</div>
				</div>}
			</div>
		</div>
	);
};

export default BodyCardInbox;
