import React from "react";
import AboutHero from "../components/about/AboutHero";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import Background from "../assets/images/Services-employer.jpg";
import AboutEmployer from "../components/about/AboutEmployer";
import EmployerDetails from "../components/EmployerDetails";
// import Logo from "../assets/images/Logo-HR-Globe-Profil.png";
import Logo from "../assets/images/logo-transparant.png";

export default function Employer() {
	return (
		<>
			<Header
				logo={Logo}
				header="pxp-header fixed-top"
				nav="pxp-nav dropdown-hover-all pxp-light d-none d-xl-block"
				extraNav="pxp-user-nav d-none d-sm-flex"
			/>
			<AboutHero
				bg={Background}
				content={() => (
					<div className="text-center">
						<h1
							style={{
								fontWeight: 50,
								fontSize: "48px",
								// fontFamily: "barlow"
							}}
						>
							Notre{" "}
							<b
								style={{
									fontWeight: "normal",
									fontSize: "48px",
									// fontFamily: "barlow"
								}}
							>
								Savoir-faire{" "}
							</b>
							, Notre{" "}
							<b
								style={{
									fontWeight: "bold",
									fontSize: "48px",
								}}
							>
								Expertise{" "}
							</b>{" "}
							à votre{" "}
							<b
								style={{
									fontWeight: "bold",
									fontSize: "48px",
								}}
							>
								Service
							</b>
						</h1>
					</div>
				)}
				// content="Notre Savoir-faire, Notre Expertise à votre Service"
			/>
			<AboutEmployer />
			<EmployerDetails />
			<Footer />
		</>
	);
}

// todo
// make template layout to avoid importing components in evry new page
