import React, { useEffect, useState } from "react";
import { Input, Select, Textarea } from "../../components/inputDash/index";
import { instance } from "../../lib/axios";
import axios from "axios";
import SubmitButton from "../../components/submitButton/index";
import { Eggy } from '@s-r0/eggy-js';

import { useFormik } from "formik";
import * as Yup from "yup";

export const CreatOffer = ({ data }) => {
	const { id, companieId } = data;
	const [display, setDisplay] = useState(false);

	const [carrerLevel, setCarrerLevel] = useState([]);

	const [employmentType, setEmploymentType] = useState([]);
	const [salaryRange, setSalaryRange] = useState([]);
	const [domaines, setDomaineDactivite] = useState([]);
	const [country, setCountries] = useState([]);
	const [city, setCity] = useState([]);

	const years = [
		{ name: "1", id: "one" },
		{ name: "2", id: "two" },
		{ name: "3", id: "three" },
		{ name: "4", id: "four" },
		{ name: "5", id: "five" },
		{ name: "6", id: "six" },
		{ name: "7", id: "seven" },
		{ name: "8", id: "eight" },
		{ name: "9", id: "nine" },
		{ name: "10", id: "ten" },
	];

	useEffect(() => {
		instance
			.get(`/carrer-levels`)
			.then((res) => {
				const data = res?.data?.results;
				setCarrerLevel(data);
			})
			.catch((err) => {
				console.log(err);
			});
		instance
			.get(`/employer-types`)
			.then((res) => {
				const data = res?.data?.results;
				setEmploymentType(data);
			})
			.catch((err) => {
				console.log(err);
			});
		instance
			.get(`/salary-ranges`)
			.then((res) => {
				const data = res?.data?.results;
				setSalaryRange(data);
			})
			.catch((err) => {
				console.log(err);
			});
		instance
			.get(`/domaines`)
			.then((res) => {
				const data = res?.data?.results;
				setDomaineDactivite(data);
			})
			.catch((err) => {
				console.log(err);
			});
		instance
			.get(`/countries`)
			.then((res) => {
				const data = res?.data?.results;
				setCountries(data);
			})
			.catch((err) => {
				console.log(err);
			});
		instance
			.get(`/cites`)
			.then((res) => {
				const data = res?.data?.results;
				setCity(data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const validationSchema = Yup.object({
		name: Yup.string(),
		discription: Yup.string(),
		carrerLevelId: Yup.number(),
		employmentTypeId: Yup.number(),
		salaryRangeId: Yup.number(),
		yearsExp: Yup.string(),
		domaineDactiviteId: Yup.number(),
		companieId: Yup.number(),
		employerId: Yup.number(),
		countryId: Yup.number(),
		cityId: Yup.number(),
	});

	const formik = useFormik({
		initialValues: {
			name: "",
			discription: "",
			carrerLevelId: 0,
			employmentTypeId: 0,
			salaryRangeId: 0,
			yearsExp: "",
			domaineDactiviteId: 0,
			companieId: companieId,
			employerId: id,
			countryId: 0,
			cityId: 0,
		},
		validationSchema,
		onSubmit: (values) => {
			setDisplay(true);
			axios
				.post(`${process.env.REACT_APP_API_URL}/annonces`, {
					...values,
					carrerLevelId: Number(values?.carrerLevelId),
					employmentTypeId: Number(id),
					salaryRangeId: Number(values?.salaryRangeId),
					yearsExp: values?.yearsExp,
					domaineDactiviteId: Number(values?.domaineDactiviteId),
					companieId: Number(companieId),
					employerId: Number(values?.employerId),
					countryId: Number(values?.countryId),
					cityId: Number(values?.cityId),
				})
				.then(async(res) => {
					await Eggy({
						title: "Success",
						message: "offer created !",
						type: 'success',
					});
					setDisplay(false);
				})
				.catch(async(err) => {
					await Eggy({
						title: "Error",
						message: "offer doesn't created !",
						type: 'error',
					});
					console.log(err);
				});
		},
	});

	return (
		<div
			style={{
				justifySelf: "center",
				padding: "100px 0px",
				width: "80%",
			}}
		>
			<div className="pxp-dashboard-content-details">
				<h1>Nouvelle offre d'emploi</h1>
				<p className="pxp-text-light">
					Ajoutez un nouveau poste à la liste des postes de votre
					entreprise.
				</p>
				<form onSubmit={formik.handleSubmit}>
					<div className="row mt-4 mt-lg-5">
						<div className="col-xxl-8">
							<div className="row">
								<Input
									formik={formik}
									title={"Titre d'emploi"}
									id={"name"}
								/>
							</div>
							<div className="row">
								<div className="col-sm-6">
									<Select
										formik={formik}
										title={"Ville"}
										id={"cityId"}
										list={city}
									/>
								</div>
								<div className="col-sm-6">
									<Select
										formik={formik}
										title={"Pays"}
										id={"countryId"}
										list={country}
									/>
								</div>
							</div>
							<div className="row">
								<Select
									formik={formik}
									title="Domaine d'activité"
									id="domaineDactiviteId"
									list={domaines}
								/>
							</div>
							<div className="row">
								<Textarea
									formik={formik}
									title={"Description de l'emploi"}
									id={"discription"}
								/>
							</div>
							<div className="row">
								<div className="col-sm-6">
									<Select
										formik={formik}
										title={"Expérience"}
										id={"yearsExp"}
										list={years}
									/>
								</div>
								<div className="col-sm-6">
									<Select
										formik={formik}
										title={"Niveau de carrière"}
										id={"carrerLevelId"}
										list={carrerLevel}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6">
									<Select
										formik={formik}
										title={"Type d'emploi"}
										id={"employmentTypeId"}
										list={employmentType}
									/>
								</div>
								<div className="col-sm-6">
									<Select
										formik={formik}
										title={"Échelle salariale"}
										id={"salaryRangeId"}
										list={salaryRange}
									/>
								</div>
							</div>
						</div>
					</div>
					<SubmitButton display={display} />
				</form>
			</div>
		</div>
	);
};
