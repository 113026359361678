/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from "react";
import FooterCardInbox from "./footerCardIndex";
import HeaderCardInbox from "./headerCardInbox";
import BodyCardInbox from "./bodyCardInbox";
import { AuthContext } from "../../context/Auth";
const CardInbox = ({ user, messages, candidate, employer, canversationInfo }) => {

const {auth} = useContext(AuthContext);
const [text, setText] = useState("");
useEffect(()=> {
},[text])

	return (
		<div className="col-xxl-8">
			<div className="pxp-dashboard-inbox-messages-container">
				<HeaderCardInbox user={canversationInfo?.candidateId === auth?.id ? candidate : employer} />
				<BodyCardInbox
					messages={messages}
					candidate={candidate}
					employer={employer}
					text={text}
				/>
				<FooterCardInbox canversationInfo={canversationInfo} setText={setText} style={{paddingBottom:"100px" , border:"1px solid black !important", background:"red !important"}} />
			</div>
		</div>
	);
};

export default CardInbox;
