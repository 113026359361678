import React from "react";
// import Bg from "../../../assets/images/ph-small.jpg";
// import ComLogo from "../../../assets/images/company-logo-1.png";

function Content() {
	return (
		<div
		style={{
			justifySelf: "center",
			padding: "100px 0px",
			width: "80%",
		}}
	>
			<div className="pxp-dashboard-content-details">
				<h1>Candidates</h1>
				<p className="pxp-text-light">
					Detailed list of candidates that applied for your job
					offers.
				</p>

				<div
					style={{
						minHeight: "80vh",
					}}
				></div>
			</div>

			<footer>
				<div className="pxp-footer-copyright pxp-text-light">
					© {new Date().getFullYear()} World select. All Right Reserved.
				</div>
			</footer>
		</div>
	);
}

export default Content;
