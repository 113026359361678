/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const NextPageList = ({ setPage, page, rowslenght,
    setRowslenght }) => {
    return (
        <div className="row mt-4 mt-lg-5 justify-content-between align-items-center">
            <div className="col-auto">
                {/* <nav className="mt-3 mt-sm-0" aria-label="Applications list pagination">
                    <ul className="pagination pxp-pagination">
                        <li className="page-item active" aria-current="page">
                            <span className="page-link">1</span>
                        </li>
                        <li onClick={() => {
                            setPage(page + 5)
                        }} className="page-item"><a className="page-link" href="#">2</a></li>
                        <li onClick={() => {
                            setPage(page + 5)
                        }} className="page-item"><a className="page-link" href="#">3</a></li>
                    </ul>
                </nav> */}
            </div>
            <div onClick={() => {
                setRowslenght(rowslenght + 5)
            }} className="col-auto">
                <a href="#" className="btn rounded-pill pxp-section-cta mt-3 mt-sm-0">Show me more<span className="fa fa-angle-right"></span></a>
            </div>
        </div>
    );
}

export default NextPageList;
