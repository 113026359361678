import React from "react";

export default function AboutUsdetails() {
	return (
		<section className="pt-100 pb-100" 
		// style={{ backgroundColor: "#fff" }}
		// style={{ backgroundColor: COLORS.customMainColorLight }}

		>
			<div className="pxp-container" >
				<div className="row">
					{/* <h2 className="pxp-section-h2 text-center">
						Comment ça marche
					</h2> */}
					{/* <div className="col-lg-12"> */}
					<div className="d-flex flex-column flex-lg-row justify-content-around how-to-sec pxp-animate-in-top pxp-in mt-4 mt-md-5 pxp-animate-in">
						<div className="how-to col d-flex flex-column align-items-center">
							<div
								style={{
									height: "300px",
									width: "300px",
									borderRadius: "50%",
									backgroundColor: "transparent",
									border: "1px dashed",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									marginBottom: "20px",
								}}
							>
								<div
									style={{
										backgroundColor: "#fff",
										height: "250px",
										width: "250px",
										borderRadius: "50%",
										color: "#000",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "28px",
										fontWeight: "700",
										boxShadow: "rgba(129, 207, 241, 0.26) 0px 10px 20px",
									}}
								>
									Employeur
								</div>
							</div>
							<div
								style={{
									width: "100%",
									display: "flex",
									alignItems: "flex-start",
									flexDirection: "column",
								}}
							>
								<div
									className="pxp-cities-card-2-name text-left"
									style={{ textAlign: "left" }}
								>
									• Développer une équipe compétente et
									dynamique
								</div>
								<div
									className="pxp-cities-card-2-name"
									style={{ textAlign: "left" }}
								>
									• Dénicher des candidats performants et
									qualifiés
								</div>
								<div
									className="pxp-cities-card-2-name"
									style={{ textAlign: "left" }}
								>
									• Réduire votre taux de roulement
								</div>
							</div>
						</div>
						<div className="how-to col d-flex flex-column align-items-center">
							<div
								style={{
									height: "300px",
									width: "300px",
									borderRadius: "50%",
									backgroundColor: "transparent",
									border: "1px dashed",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									marginBottom: "20px",
								}}
							>
								<div
									style={{
										backgroundColor: "#fff",
										height: "250px",
										width: "250px",
										borderRadius: "50%",
										color: "#000",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "28px",
										fontWeight: "700",
										boxShadow: "rgba(129, 207, 241, 0.26) 0px 10px 20px",
									}}
								>
									Candidat
								</div>
							</div>
							<div
								style={{
									width: "100%",
									display: "flex",
									alignItems: "flex-start",
									flexDirection: "column",
								}}
							>
								<div
									className="pxp-cities-card-2-name"
									style={{ textAlign: "left" }}
								>
									• Des opportunités de carrières dénichées à
									l'échelle internationale.
								</div>
								<div
									className="pxp-cities-card-2-name"
									style={{ textAlign: "left" }}
								>
									• Des emplois bien ciblés qui correspondent
									à votre profil
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
