import React from "react";
import AboutSection from "../components/about/AboutUsDescription";
import AboutHero from "../components/about/AboutHero";
import Footer from "../components/layout/Footer";
import Header from "../components//layout/Header";
import AboutUsdetails from "../components/about/AboutUsDetails";
import Background from "../assets/images/about_hr_bg.jpg";
// import Logo from "../assets/images/Logo-HR-Globe-Profil.png";
import Logo from "../assets/images/logo-transparant.png";

export default function About() {
	return (
		<>
			<Header
				logo={Logo}
				header="pxp-header fixed-top pxp-no-bg"
				nav="pxp-nav dropdown-hover-all pxp-light d-none d-xl-block"
				extraNav="pxp-user-nav d-none d-sm-flex"
			/>
			<AboutHero
				bg={Background}
				content={() => (
					<div className="text-center">
						<h1
							style={{
								fontWeight: 50,
								fontSize: "48px",
								// fontFamily: "barlow"
							}}
						>
							<b
								style={{
									fontWeight: "normal",
									fontSize: "48px",
									// fontFamily: "barlow"
								}}
							>
								Des solutions emploi{" "}
							</b>
							sur{" "}
							<b
								style={{
									fontWeight: "normal",
									fontSize: "48px",
								}}
							>
								mesure{" "}
							</b>{" "}
							pour{" "}
							<b
								style={{
									fontWeight: "bold",
									fontSize: "48px",
								}}
							>
								vous
							</b>
						</h1>
					</div>
				)}
			/>
			<AboutSection />
			<AboutUsdetails />
			<Footer />
		</>
	);
}
