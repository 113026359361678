/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { instance } from "../../lib/axios";
import { useParams } from 'react-router-dom';
import { Items2 } from "../../components/2Items";

export const CompanyPage = () => {

    let { id } = useParams();
    const [company, setCompany] = useState(null)
    useEffect(() => {
        instance.get(`/companies/${id}`).then((res) => {
            const data = res?.data?.results;
            setCompany(data)
        }).catch((err) => { console.log(err) });
    }, [])
    useEffect(() => {
       
    }, [company])
    return (
        <>
           <section className="pxp-single-company-hero pxp-cover" style={{backgroundImage: "url(images/ph-big.jpg);"}}>
            <div className="pxp-hero-opacity"></div>
            <div className="pxp-single-company-hero-caption">
                <div className="pxp-container">
                    <div className="pxp-single-company-hero-content">
                        <div className="pxp-single-company-hero-logo" style={{backgroundImage: "url(images/company-logo-1.png);"}}></div>
                        <div className="pxp-single-company-hero-title">
                            <h1>{company?.name}</h1>
                            <div className="pxp-single-company-hero-location"><span className="fa fa-globe"></span>{company?.city?.name} - {company?.Country?.name}</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section className="mt-100">
            <div className="pxp-container">
                <div className="row">
                    <div className="col-lg-7 col-xl-8 col-xxl-9">
                        <div className="pxp-single-company-content">
                            <h2>About {company?.name}</h2>
                            <p>{company?.discription}</p>
                        </div>
                    </div>
                    <div className="col-lg-5 col-xl-4 col-xxl-3">
                        <div className="pxp-single-company-side-panel mt-5 mt-lg-0">
                            <Items2 label={"Industry"} title={company?.name} />
                            <Items2 label={"Company size"} title={company?.companySize} />
                            <Items2 label={"Founded in"} title={company?.foundedIn} />
                            <Items2 label={"Phone"} title={company?.phone} />
                            <Items2 label={"Email"} title={company?.email} />
                            <Items2 label={"Location"} title={`${company?.city?.name} - ${company?.Country?.name}`} />
                            <Items2 label={"Website"} title={company?.website} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}