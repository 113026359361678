import React from "react";
// import Logo from "./assets/images/Logo-HR-Globe-Profil.png";
import Logo from "./assets/images/logo-transparant.png";
import { Suspense } from "react";
import PreLoader from "./components/PreLoader";

const Header = React.lazy(() => import("./components/layout/Header"));
const Footer = React.lazy(() => import("./components/layout/Footer"));
const Hero = React.lazy(() => import("./components/layout/Hero"));
const How = React.lazy(() => import("./components/How"));
const Numbers = React.lazy(() => import("./components/Numbers"));
const About = React.lazy(() => import("./components/about/About"));

function App() {
	return (
		<>
			<Suspense fallback={<PreLoader />}>
				<Header
					logo={Logo}
					header="pxp-header fixed-top"
					nav="pxp-nav dropdown-hover-all d-none d-xl-block"
					extraNav="pxp-user-nav d-none d-sm-flex"
				/>
				<Hero />
				<About />
				<Numbers />
				<How />
				<Footer />
			</Suspense>
		</>
	);
}

export default App;
