import React from "react";
// import $ from "jquery";
// import onContentScroll from "../lib/main/onContentScroll";
// import handlePreloader from "../lib/main/handlePreloader";
// import windowResizeHandler from "../lib/main/windowResizeHandler";

export default function PreLoader() {
	return (
		<div className="pxp-preloader">
			<span>Loading...</span>
		</div>
	);
}
