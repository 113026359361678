import React from "react";
import dayjs from "dayjs";

import RowTable from "../../components/table/row";
import OfferRowTable from "../../components/table/row/OfferRowTable.js"
export const BodyTAble = ({ data }) => {
    return (
        <tbody >
            {data?.map((elem, index) => {
                const row = {
                    job: elem?.name,
                    country: elem?.Country?.name,
                    city: elem?.city?.name,
                    company: elem?.companie?.name,
                    category: elem?.domaineDactivite?.name,
                    type: elem?.carrerLevel?.name,
                    date: dayjs(elem?.createdAt).format(
                        "DD-MM-YYYY HH:mm"),
                    offerLink: `/annonce/${elem?.id}`,
                    companyLink: `/company/${elem?.companieId}`
                }
                return <RowTable Row={row} Key={index} />
            })}
        </tbody>
    );
}

export const OffersBodyTAble = ({ data }) => {
    return (
        <tbody>
            {data?.map((elem) => {
                const row = {
                    id: elem?.id,
                    job: elem?.name,
                    country: elem?.Country?.name,
                    city: elem?.city?.name,
                    category: elem?.domaineDactivite?.name,
                    type: elem?.carrerLevel?.name,
                    date: dayjs(elem?.createdAt).format(
                        "DD-MM-YYYY HH:mm"),
                    offerLink: `/annonce/${elem?.id}`,
                    status: (elem?.isActive) ? "Publish" : "Draft",
                }
                return <OfferRowTable Row={row} />
            })}
        </tbody>
    );
}