import React from "react";
import Globe from "../../assets/images/Carte-Monde-HR-GLOBE.png";

export default function AboutUsDesription() {
	return (
		<section
			className="pt-100 pb-100"
			style={{
				backgroundImage: `url(${Globe})`,
				backgroundPositionY: " -100px",
			}}
		>
			<div
				style={{ width: "100%" }}
				className="row justify-content-center"
			>
				<div className="col-xl-7 col-xxl-6">
					<div className="mt-4 mt-md-5 text-center">
						<p>
							Le Succès de <b>World select</b> réside dans sa capacité
							de mettre en relation les entreprises offrant des
							postes à pourvoir avec les profils opportuns des
							candidats en quête d'emploi et ce dans plusieurs
							secteurs d'activités et sur plusieurs villes en
							Amérique du Nord, en Europe et en Afrique.
						</p>
						<p>
							Note vocation internationale et ouverture
							multiculturelle nous procurant une grande capacité
							multidisciplinaire qui nous permet de répondre
							méticuleusement aux besoins de nos clients durables,
							d'investir sur le potentiel humain qu'on leur offre,
							et ceci dans plusieurs domaines d'activités.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}
