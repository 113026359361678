import React from "react";
import { Link } from "react-router-dom";
import { COLORS } from "../../utils";
// import Logo from "../../assets/images/Logo-HR-Globe-Profil.png";
import Logo from "../../assets/images/logo-transparant.png";

export default function Footer() {
	return (
		<footer className="pxp-main-footer">
			<div
				className="pxp-main-footer-top pt-5"
				style={{ backgroundColor: "#fff" }}
			>
				<div className="pxp-container">
					<div className="row">
						<div className="col-lg-6 col-xl-5 col-xxl-4 mb-4">
							<div className="pxp-footer-logo">
								<Link to="/" className="pxp-animate">
									<img
										style={{ height: "37px" }}
										src={Logo}
										alt="logo"
									/>
								</Link>
							</div>
							<div className="pxp-footer-section mt-3 mt-md-4">
								<h3>Contact</h3>
								<div className="pxp-footer-phone">
									+1 514 385-0202
								</div>
							</div>
							<div className="mt-3 mt-md-4 pxp-footer-section">
								<div className="pxp-footer-text">
									107-10150 Avenue Papineau,
									<br />
									Montréal, Québec, H2B 2A2
									<br />
									info@wselect.ca
									<br />
									Num Permis : 2202513
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-xl-7 col-xxl-8">
							<div className="row">
								<div className="col mb-4">
									<div className="pxp-footer-section">
										<h3>A propos de World select</h3>
										<p>
											WORLD SELECT est un cabinet
											d'intermédiation dans le domaine de
											recrutement implanté dans plusieurs
											villes en Amérique du nord, en
											Europe et en Afrique. Notre vocation
											internationale et ouverture
											multiculturelle nous procurent une
											grande capacité multidisciplinaire
											qui nous permet de répondre
											méticuleusement any besoins de nos
											dients.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="pxp-main-footer-bottom"
				style={{ backgroundColor: COLORS.customMainColorLight }}
			>
				<div className="pxp-container">
					<div className="row justify-content-between align-items-center">
						<div className="col-lg-auto">
							<div className="pxp-footer-copyright pxp-text-light">
								<p style={{ fontSize: "12px" }}>
									© {new Date().getFullYear()} World select.
									All Right Reserved.
								</p>
							</div>
						</div>
						<div className="col-lg-auto">
							<div
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<div className="pxp-footer-copyright pxp-text-light">
									<Link
										to="/Conditions-utilisation"
										className="ishover"
									>
										Conditions d'utilisation
									</Link>
								</div>
								<div className="pxp-footer-copyright pxp-text-light">
									<p>
										<Link
											to="/Conditions-de-ventes"
											className="ishover"
										>
											Conditions de ventes
										</Link>
									</p>
								</div>
							</div>
						</div>
						<div className="col-lg-auto">
							<h6 className="pxp-footer-social mt-3 mt-lg-0">
								Retrouvez-nous sur les reseaux sociaux
							</h6>
							<div className="pxp-footer-social mt-3 mt-lg-0">
								<ul className="list-unstyled">
									<li>
										<a
											href="https://www.facebook.com/hrglobeca"
											target="_blank"
											rel="noreferrer"
										>
											<span className="fa fa-linkedin"></span>
										</a>
									</li>
									<li>
										<a
											href="https://www.facebook.com/hrglobeca"
											target="_blank"
											rel="noreferrer"
										>
											<span className="fa fa-facebook"></span>
										</a>
									</li>
									<li>
										<a
											href="https://www.instagram.com/hrglobeca"
											target="_blank"
											rel="noreferrer"
										>
											<span className="fa fa-instagram"></span>
										</a>
									</li>
									<li>
										<a
											href="mailto: info@wselect.ca"
											target="_blank"
											rel="noreferrer"
										>
											<span className="fa fa-envelope"></span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}
